import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import MESSAGES from 'config/messages';
import cuid from 'cuid';
import { GlobalAlertParams, PanoUser } from 'types';
import { nowMs } from 'utils/moment';
import { truncateCuid } from 'utils/string';

import { BASE_API_URL, GEOJSON_SERVER, MAPBOX_BASE_API_URL } from '../base';
import { API_TIMEOUT, API_TRACE_HEADER, EMPTY_FUNCTION, USER_FIELD } from '../constants';

import { errorHandler } from './api.helpers';

const createApiInstance = (baseURL: string, timeout = API_TIMEOUT): AxiosInstance =>
  axios.create({
    baseURL,
    timeout,
  });

const headerConfig = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  request.headers[API_TRACE_HEADER] = truncateCuid(cuid());
  return request;
};

export const mapBoxApi: AxiosInstance = createApiInstance(MAPBOX_BASE_API_URL);
export const geoApi: AxiosInstance = createApiInstance(GEOJSON_SERVER);
export const publicApi: AxiosInstance = createApiInstance(BASE_API_URL);

publicApi.interceptors.request.use(headerConfig);
geoApi.interceptors.request.use(headerConfig);
const api: AxiosInstance = createApiInstance(BASE_API_URL);

let requestsInterceptor: number = null;

declare module 'axios' {
  export interface AxiosRequestConfig {
    /** If true, we should skip our generic error handling */
    shouldSkipErrorHandling?: boolean;
  }
}
/**
 * Public API interceptor
 * @param setGlobalAlert
 */
export const setupPublicApi = (setGlobalAlert: (_alert: GlobalAlertParams) => void = EMPTY_FUNCTION): void => {
  publicApi.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      const statusCode: number = error?.response?.status;
      if (statusCode === undefined) {
        setGlobalAlert({ message: MESSAGES.API_GENERAL_ERROR });
        return Promise.reject(new Error(MESSAGES.API_GENERAL_ERROR));
      }
      return Promise.reject(error);
    },
  );
};

// Initialize auth token. If given, set it; otherwise, fetch it from localStorage
export const initApiToken = (
  requireLogin: (_response: AxiosResponse) => void,
  user: PanoUser = null,
  setGlobalAlert: (_alert: GlobalAlertParams) => void = EMPTY_FUNCTION,
  setDataConflictError: (_type: string) => void = EMPTY_FUNCTION,
): void => {
  let authToken: string = user?.bearer;
  try {
    if (user) {
      window.localStorage.setItem(USER_FIELD, JSON.stringify(user));
    } else {
      const localUser: PanoUser = JSON.parse(window.localStorage.getItem(USER_FIELD));
      authToken = localUser?.bearer;
    }

    if (authToken) {
      const authHeader: string = `Bearer ${authToken}`;
      api.defaults.headers.common['Authorization'] = authHeader;

      // remove previous interceptors with outdated token
      if (requestsInterceptor !== null) {
        api.interceptors.request.eject(requestsInterceptor);
      }

      requestsInterceptor = api.interceptors.request.use(function (config) {
        config.headers.Authorization = authHeader;
        return config;
      });
    }
  } catch (e) {
    console.log(e);
  }

  api.interceptors.request.use(headerConfig);

  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      return errorHandler({ error, requireLogin, setDataConflictError, setGlobalAlert });
    },
  );
};

// Response in cache expires
const API_CACHE_EXPIRE_TIME_MS: number = 1000 * 60 * 60 * 24;

// The `data` in cache could be `any` type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiCache: Record<string, { timestamp: number; data: any }> = {} as Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { timestamp: number; data: any }
>;

// Remove expired data items in API cache
const cacheExpired = (key: string): boolean => {
  // The `data` in cache could be `any` type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedValue: { timestamp: number; data: any } = apiCache[key];
  if (cachedValue) {
    const expired: boolean = nowMs() - cachedValue.timestamp > API_CACHE_EXPIRE_TIME_MS;
    if (expired) {
      delete apiCache[key];
      return true;
    }
  }
  return false;
};

// @todo consider dedicated endpoint cache policy
const API_CACHE_ON: boolean = false;
const axiosInstances: [AxiosInstance, AxiosInstance, AxiosInstance, AxiosInstance] = [
  api,
  geoApi,
  publicApi,
  mapBoxApi,
];
axiosInstances.forEach((axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (!API_CACHE_ON) {
      return config;
    }

    const cacheKey: string = config.url;
    // Cache only GET requests
    if (config.method.toLowerCase() !== 'get') {
      return config;
    }

    // Cache expires
    if (cacheExpired(cacheKey)) {
      return config;
    }

    // if cached
    if (apiCache[cacheKey]) {
      const timestamp: number = apiCache[cacheKey].timestamp;
      const invalidate: boolean = new Date().getTime() - timestamp > 1000 * 60 * 60;
      // 1h expiration time
      if (invalidate) return config;
      return Promise.resolve(apiCache[cacheKey].data);
    }
    return config;
  });

  axiosInstance.interceptors.response.use((response) => {
    if (API_CACHE_ON && response.status === 200 && response.config.method.toLowerCase() === 'get') {
      const cacheKey: string = response.config.url;
      // update cache
      apiCache[cacheKey] = {
        data: response.data,
        timestamp: new Date().getTime(),
      };
    }
    return response;
  });
});

export const authApi = (): AxiosInstance => {
  return api;
};
