import React from 'react';
import Link from 'componentLibrary/Link';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './Footer.styles';

export default function Footer(): React.ReactElement {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box component='footer' sx={styles.footer} data-cy='footer'>
      <Box>©{new Date().getFullYear()} Pano AI &nbsp;</Box>
      <Box>
        {isNotMobile && <Typography component={'span'}>|&nbsp;</Typography>}
        <Link to='/terms' target='_blank'>
          Terms of Service
        </Link>
        &nbsp;|&nbsp;
        <Link to='/privacy' target='_blank'>
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
}
