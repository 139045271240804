import { USER_EMAIL_FIELD } from 'config/base';
import { localStorageEffect } from 'data/effect';
import { atom, DefaultValue, RecoilState, selector } from 'recoil';
import { CanvasPositionMap, GlobalAlertParams, MyCorrelationIdMap, UpdateCanvasPosParams } from 'types';
import { nowMs } from 'utils/moment';

const rscMyCorrelationIds: RecoilState<MyCorrelationIdMap> = atom<MyCorrelationIdMap>({
  key: 'rscMyCorrelationIds',
  default: {},
});

const rscSetMyCorrelationId: RecoilState<string> = selector<string>({
  key: 'rscMyCorrelationIds/update',
  get: () => null, // @note never call `get`
  set: ({ get, set }, myCorrelationId) => {
    if (myCorrelationId instanceof DefaultValue) {
      return;
    }

    const myIds: MyCorrelationIdMap = get(rscMyCorrelationIds);
    set(rscMyCorrelationIds, {
      ...myIds,
      [myCorrelationId]: nowMs(),
    });
  },
});

// Error message globally as content of toast
const rscGlobalAlert: RecoilState<GlobalAlertParams> = atom<GlobalAlertParams>({
  key: 'rscGlobalAlert',
  default: null,
});

// User input email stored in localStorage
const rscLocalEmailInfo: RecoilState<string> = atom<string>({
  key: 'rscLocalEmailInfo',
  default: '',
  effects: [localStorageEffect(USER_EMAIL_FIELD, null)],
});

// Scrolling position of incident list to top of page
const rscListScrollTop: RecoilState<number> = atom<number>({
  key: 'rscListScrollTop',
  default: 0,
});

// data conflict error
const rscDataConflictError: RecoilState<string> = atom<string>({
  key: 'rscDataConflictError',
  default: '',
});

const rscCanvasPos: RecoilState<CanvasPositionMap> = atom<CanvasPositionMap>({
  key: 'rscCanvasPos',
  default: {},
});

const rscUpdateCanvasPos: RecoilState<UpdateCanvasPosParams> = selector<UpdateCanvasPosParams>({
  key: 'rscCanvasPos/update',
  get: () => null, // @note never call `get`
  set: ({ get, set }, params) => {
    if (params instanceof DefaultValue) {
      return;
    }

    const { canvasIndex, posForCanvas } = params;
    const canvasPos: CanvasPositionMap = get(rscCanvasPos);
    set(rscCanvasPos, {
      ...canvasPos,
      [canvasIndex]: posForCanvas,
    });
  },
});

/** The time when incident list starts to load */
const rscIncidentLoadingStart: RecoilState<number> = atom<number>({
  key: 'rscIncidentLoadingStart',
  default: 0,
});

/** Whether or not the user is actively viewing the browser tab */
const rscIsTabVisible: RecoilState<boolean> = atom<boolean>({
  key: 'rscIsTabVisible',
  default: true,
});

export {
  rscCanvasPos,
  rscDataConflictError,
  rscGlobalAlert,
  rscIncidentLoadingStart,
  rscIsTabVisible,
  rscListScrollTop,
  rscLocalEmailInfo,
  rscMyCorrelationIds,
  rscSetMyCorrelationId,
  rscUpdateCanvasPos,
};
