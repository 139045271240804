import {
  rsiIncidentMark,
  rsiIncidentMarkPositionForPopup,
  rsiIsMarkingFire,
  rspwIsPicUserAvailableSynced,
  rstDuplicateIncidentId,
  rstHasBeenRedirected,
  rstIsTaskAcknowledged,
  rstIsTaskCompletionInProgress,
  rstTask,
  rstTaskDisposition,
  rstTaskStationId,
} from 'data';
import { TaskApis } from 'data/proxyApi';
import useActivity from 'hooks/useActivity/useActivity';
import useIncidents from 'hooks/useIncidents';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PICTaskDisposition, Task } from 'types';

interface Returned {
  /**
   * Gets a new task for the user
   * Creates or extends the user's activity
   */
  fetchTask?: () => Promise<Task>;
  /**
   * Extends the user's active task
   * Creates or extends the user's activity
   */
  extendActiveTask?: () => Promise<boolean>;
  /**
   * Completes the users active task
   * - Clears any mark, disposition
   * - Updates users availability
   * - Extends or Ends their activity']
   * @returns whether the task was successfully extended or not=
   */
  completeActiveTask?: (isUserAcceptingMoreTasks: boolean) => Promise<void>;
  /**
   * This is a helper function meant to consolidate the things that have to happen
   * in order for a user to be ready for another task
   */
  clearActiveTask: () => void;
}

/**
 * @NOTE This is purposefully tightly coupled to activity tracking, as activitiy
 * tracking is directly related to wether a user is working on a task
 * @returns functions for managing and assigning tasks
 */
export default function useTasks(): Returned {
  const [task, setTask] = useRecoilState(rstTask);
  const stationId = useRecoilValue(rstTaskStationId);
  const [disposition, setDisposition] = useRecoilState(rstTaskDisposition);
  const [mark, setMark] = useRecoilState(rsiIncidentMark);
  const setIsPicUserAvailable = useSetRecoilState(rspwIsPicUserAvailableSynced);
  const setHasBeenRedirected = useSetRecoilState(rstHasBeenRedirected);
  const setIsMarkingFire = useSetRecoilState(rsiIsMarkingFire);
  const duplicateId = useRecoilValue(rstDuplicateIncidentId);
  const setIsTaskAcknowledged = useSetRecoilState(rstIsTaskAcknowledged);
  const setIncidentMarkPosition = useSetRecoilState(rsiIncidentMarkPositionForPopup);
  const setIsTaskCompletionInProgress = useSetRecoilState(rstIsTaskCompletionInProgress);
  const { fetchIncidents } = useIncidents();
  const { createOrExtendActivity, endActivity } = useActivity();

  const fetchTask = async () => {
    createOrExtendActivity();
    const task = await TaskApis.apiFetchTask();

    setTask(task);
    if (task) {
      setIsTaskAcknowledged(false);
    }

    return task;
  };

  const extendActiveTask = async () => {
    try {
      createOrExtendActivity();
      const res = await TaskApis.apiExtendTask(task.taskId);

      if (res === 'error') {
        return false;
      } else if (res === 'expired') {
        clearActiveTask();
        setIsPicUserAvailable(false);

        return false;
      }

      setTask((task) => ({
        ...task,
        expirationTime: res,
      }));
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const clearActiveTask = () => {
    setTask(null);
    setDisposition(null);
    setMark(null);
    setHasBeenRedirected(false);
    setIsMarkingFire(false);
    setIsTaskCompletionInProgress(false);
  };

  const completeActiveTask = async (isUserAcceptingMoreTasks: boolean) => {
    setIsTaskCompletionInProgress(true);
    const res = await TaskApis.apiCompleteTask({ taskId: task.taskId, mark, disposition, duplicateId, stationId });
    if (disposition === PICTaskDisposition.needsReview) {
      await fetchIncidents({
        stationId: stationId,
        status: ['possible', 'confirmed'],
      });
    }

    setIncidentMarkPosition(null);

    const isSubmittedTaskExpired = res === 'expired';
    clearActiveTask();

    if (!isUserAcceptingMoreTasks || isSubmittedTaskExpired) {
      endActivity();
      setIsPicUserAvailable(false);
    } else {
      createOrExtendActivity();
      const newTask = await fetchTask();
      setTask(newTask);
    }
  };

  return {
    clearActiveTask,
    completeActiveTask,
    extendActiveTask,
    fetchTask,
  };
}
