export const replaceArrayIndex = <T>(array: T[], index: number, newObj: T): T[] => {
  if (index >= 0) {
    return [...array.slice(0, index), newObj, ...array.slice(index + 1)];
  }

  return array;
};

export const replaceArrayIndexes = <T>(array: T[], indexes: number[], items: T[]): T[] => {
  if (!Array.isArray(array) || !Array.isArray(indexes) || !Array.isArray(items)) {
    console.warn('[dev] wrong parameters for function replaceArrayIndexes');
    return [];
  }

  if (indexes.length !== items.length || indexes.find((i) => i < 0 || i >= array.length)) {
    console.warn('[dev] wrong parameters for function replaceArrayIndexes');
    return [];
  }

  for (let i: number = 0; i < indexes.length; i++) {
    array[indexes[i]] = items[i];
  }

  return [...array];
};

export const pushItemToArray = <T>(array: T[], item: T): T[] => {
  return [...array, item];
};

export const removeItemFromArray = <T>(array: T[], index: number): T[] => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const toggleItemInArray = <T>(array: T[], item: T): T[] => {
  const index: number = array.findIndex((i) => i === item);
  if (index >= 0) {
    return removeItemFromArray(array, index);
  }

  return pushItemToArray(array, item);
};

interface ObjWithId {
  id: number;
}

/**
 * Return a new copy of `array` with new items added and existing items updated
 * @param array
 * @param diff
 */
export const applyArrayDifference = <T extends ObjWithId>(array: T[], diff: T[]): T[] => {
  // In case `array` is big, create a map: [item id] => [item index in array].
  const arrayIndexMap: Record<number, number> = array.reduce((arrayMap: Record<number, number>, item, currentIndex) => {
    arrayMap[item?.id] = currentIndex;
    return arrayMap;
  }, {});
  // New items to be added
  const newOnes: T[] = diff.filter((item) => !(item.id in arrayIndexMap));
  // Items to be updated
  const updatedOnes: T[] = diff.filter((item) => item.id in arrayIndexMap);
  // We do not alter the input
  const updatedArray: T[] = [...array];
  // set updated items to the cloned array.
  updatedOnes.forEach((updatedItem) => {
    updatedArray[arrayIndexMap[updatedItem.id]] = updatedItem;
  });
  // concat new items and updated items.
  return updatedArray.concat(newOnes);
};

// Merge 2 arrays of objects with the same length
export const mergeObjectInArrays = <T>(array1: T[], array2: T[]): T[] => {
  if (array1.length !== array2.length) {
    throw new Error('The two arrays must have the same length');
  }

  return array1.map((item1, index) => {
    return {
      ...item1,
      ...array2[index],
    };
  });
};
