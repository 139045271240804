import { atom, RecoilState, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { LonLat, MapLayerPopupContent, MapLayerPopupInfo, MapLayerPopupTitles } from 'types';

/**
 * This tracks the items that are hovered for each layer
 */
export const rsmPopupTitles: RecoilState<MapLayerPopupTitles> = atom<MapLayerPopupTitles>({
  key: 'rsmPopupTitles',
  default: {
    powerline: 'Powerline Info',
    pgeFacilities: 'PGE Facilities',
    pgeOverheadMainlines: 'PGE Overhead Mainline',
    pgeOverheadTaplines: 'PGE Overhead Tapline',
    pgeUndergroundMainlines: 'PGE Underground Mainline',
    pgeUndergroundTaplines: 'PGE Underground Tapline',
    gtfaPlantationsABP: 'ABP Plantations',
    gtfaPlantationsAKD: 'AKD Plantations',
    gtfaPlantationsGPFL: 'GPFL Plantations',
    gtfaPlantationsGTFP: 'GTFP Plantations',
    gtfaPlantationsHVP: 'HVP Plantations',
    gtfaPlantationsOFO: 'OFO Plantations',
    gtfaPlantationsPFO: 'PFO Plantations',
    gtfaPlantationsSFM: 'SFM Plantations',
    gtfaPlantationsTPPL: 'TPPL Plantations',
    gtfaDispatchZones: 'Dispatch Zones',
    gtfaFocMapGrid: 'FOC Map Index',
    gtfaCfaMapGrid: 'Spatial Vision (Vic)',
    gtfaCfsMapGrid: 'CFS Map Index',
    rffPlantations: 'RFF Plantations',
    foricoPlantations: "Forico's Plantation",
    forico50k: '50K Mapsheet',
    tfsBrigade: 'TFS Brigade Boundaries',
  },
});

/**
 * This tracks the position where we show the hover popup
 */
export const rsmHoveredPopupPosition: RecoilState<LonLat> = atom<LonLat>({
  key: 'rsmHoveredPopupPosition',
  default: {
    lat: 0,
    lon: 0,
  },
});

/**
 * This tracks the items that are hovered for each layer
 */
export const rsmHoveredLayers: RecoilState<MapLayerPopupInfo> = atom<MapLayerPopupInfo>({
  key: 'rsmHoveredLayer',
  default: {
    powerline: undefined,
    pgeFacilities: undefined,
    pgeOverheadMainlines: undefined,
    pgeOverheadTaplines: undefined,
    pgeUndergroundMainlines: undefined,
    pgeUndergroundTaplines: undefined,
    gtfaPlantationsABP: undefined,
    gtfaPlantationsAKD: undefined,
    gtfaPlantationsGPFL: undefined,
    gtfaPlantationsGTFP: undefined,
    gtfaPlantationsHVP: undefined,
    gtfaPlantationsOFO: undefined,
    gtfaPlantationsPFO: undefined,
    gtfaPlantationsSFM: undefined,
    gtfaPlantationsTPPL: undefined,
    gtfaDispatchZones: undefined,
    gtfaFocMapGrid: undefined,
    gtfaCfaMapGrid: undefined,
    gtfaCfsMapGrid: undefined,
    rffPlantations: undefined,
    foricoPlantations: undefined,
    forico50k: undefined,
  },
});

/**
 * @param layerName - The name of the layer we want to get
 * @returns a setter and the value for what feature is hovered for the given layer name
 */
export const rsmHoveredLayerByName = selectorFamily<
  MapLayerPopupInfo[keyof MapLayerPopupInfo],
  keyof MapLayerPopupInfo
>({
  key: 'rsmHoveredLayerByName',
  get:
    (layerName) =>
    ({ get }) => {
      const hoveredLayers: MapLayerPopupInfo = get(rsmHoveredLayers);

      return hoveredLayers[layerName];
    },
  set:
    (layerName) =>
    ({ set }, hoveredLayerProperties) => {
      set(rsmHoveredLayers, (prevHoveredLayers) => ({
        ...prevHoveredLayers,
        [layerName]: hoveredLayerProperties,
      }));
    },
});

/**
 * This tracks the currently selected item for each layer
 */
export const rsmSelectedLayers: RecoilState<MapLayerPopupInfo> = atom<MapLayerPopupInfo>({
  key: 'rsmSelectedLayer',
  default: {
    powerline: undefined,
    pgeFacilities: undefined,
    pgeOverheadMainlines: undefined,
    pgeOverheadTaplines: undefined,
    pgeUndergroundMainlines: undefined,
    pgeUndergroundTaplines: undefined,
    gtfaPlantationsABP: undefined,
    gtfaPlantationsAKD: undefined,
    gtfaPlantationsGPFL: undefined,
    gtfaPlantationsGTFP: undefined,
    gtfaPlantationsHVP: undefined,
    gtfaPlantationsOFO: undefined,
    gtfaPlantationsPFO: undefined,
    gtfaPlantationsSFM: undefined,
    gtfaPlantationsTPPL: undefined,
    gtfaDispatchZones: undefined,
    gtfaFocMapGrid: undefined,
    gtfaCfaMapGrid: undefined,
    gtfaCfsMapGrid: undefined,
    rffPlantations: undefined,
    foricoPlantations: undefined,
    forico50k: undefined,
    tfsBrigade: undefined,
  },
});

/**
 * @param layerName - The name of the layer we want to get
 * @returns a setter and the value for what feature is selected for the given layer name
 */
export const rsmSelectedLayerByName: (
  param: keyof MapLayerPopupInfo,
) => RecoilState<MapLayerPopupInfo[keyof MapLayerPopupInfo]> = selectorFamily<
  MapLayerPopupInfo[keyof MapLayerPopupInfo],
  keyof MapLayerPopupInfo
>({
  key: 'rsmSelectedLayerByName',
  get:
    (param) =>
    ({ get }) => {
      const hoveredLayers: MapLayerPopupInfo = get(rsmSelectedLayers);

      return hoveredLayers[param];
    },
  set:
    (param) =>
    ({ set }, newValue) => {
      set(rsmSelectedLayers, (prevObject) => ({
        ...prevObject,
        [param]: newValue,
      }));
    },
});

/**
 * We currently only show information for a single asset in our hover and select popups
 * This manages the prioritization for which data is put in the popup
 */
export const rsmLayerPrioritization: RecoilValueReadOnly<(keyof MapLayerPopupInfo)[]> = selector<
  (keyof MapLayerPopupInfo)[]
>({
  key: 'rsmLayerPrioritization',
  get: ({ get }) => {
    const popupTitles: MapLayerPopupTitles = get(rsmPopupTitles);

    const keysArray: string[] = Array.from(Object.keys(popupTitles));

    return [
      ...new Set([
        'powerline',
        'pgeFacilities',
        'pgeOverheadMainlines',
        'pgeOverheadTaplines',
        'pgeUndergroundMainlines',
        'pgeUndergroundTaplines',
        'gtfaPlantationsABP',
        'gtfaPlantationsAKD',
        'gtfaPlantationsGPFL',
        'gtfaPlantationsGTFP',
        'gtfaPlantationsHVP',
        'gtfaPlantationsOFO',
        'gtfaPlantationsPFO',
        'gtfaPlantationsSFM',
        'gtfaPlantationsTPPL',
        'gtfaDispatchZones',
        'gtfaFocMapGrid',
        'foricoPlantations',
        'tfsBrigade',
        'forico50k',
        ...keysArray,
      ]),
    ] as (keyof MapLayerPopupInfo)[];
  },
});

export const rsmHoveredLayerPopupContent: RecoilValueReadOnly<MapLayerPopupContent> = selector<MapLayerPopupContent>({
  key: 'rsmHoveredLayerPopupContent',
  get: ({ get }) => {
    const hoveredLayers: MapLayerPopupInfo = get(rsmHoveredLayers);
    const popupTitles: MapLayerPopupTitles = get(rsmPopupTitles);

    const layerPrioritzation: (keyof MapLayerPopupInfo)[] = get(rsmLayerPrioritization);

    const highestPriorityActiveLayerName: string = layerPrioritzation.find(
      (layerName) => !!hoveredLayers[layerName as keyof MapLayerPopupInfo],
    );

    if (highestPriorityActiveLayerName) {
      return {
        title: popupTitles[highestPriorityActiveLayerName as keyof MapLayerPopupTitles],
        info: hoveredLayers[highestPriorityActiveLayerName as keyof MapLayerPopupInfo],
      };
    }

    return {
      title: '',
      info: undefined,
    };
  },
});

export const rsmSelectedLayerPopupContent: RecoilValueReadOnly<MapLayerPopupContent> = selector<MapLayerPopupContent>({
  key: 'rsmSelectedLayerPopupContent',
  get: ({ get }) => {
    const selectedLayers: MapLayerPopupInfo = get(rsmSelectedLayers);
    const popupTitles: MapLayerPopupTitles = get(rsmPopupTitles);

    const layerPrioritzation: (keyof MapLayerPopupInfo)[] = get(rsmLayerPrioritization);

    const highestPriorityActiveLayerName: string = layerPrioritzation.find(
      (layerName) => !!selectedLayers[layerName as keyof MapLayerPopupInfo],
    );

    if (highestPriorityActiveLayerName) {
      return {
        title: popupTitles[highestPriorityActiveLayerName as keyof MapLayerPopupTitles],
        info: selectedLayers[highestPriorityActiveLayerName as keyof MapLayerPopupInfo],
      };
    }

    return {
      title: '',
      info: undefined,
    };
  },
});
