import { PanoCanvas } from 'components/CustomCanvas/player';
import { TRACKING_ENABLED } from 'config/base';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { OpticalZoomData, PanoData, TimeLapseMode } from 'types';

export const timelapseToSecond: Record<string, number> = {
  '2m': 2 * 60,
  '10m': 10 * 60,
  '1h': 60 * 60,
  '3h': 3 * 60 * 60,
  '6h': 6 * 60 * 60,
  '12h': 12 * 60 * 60,
  '24h': 24 * 60 * 60,
};

export const maxFramesCount: number = 60;
// @todo no one-by-one frames are enforced now.
export const sparseFramesThreshold: number = 0;

export const timelapseToMode = (timelapse: string, rotationFrom: number, rotationTo: number): TimeLapseMode => {
  // console.log('timelapseToMode timelapse', timelapse, 'rotationTo', rotationTo, 'rotationFrom', rotationFrom);
  // @conflict `history` type included?
  if (['incident', 'history', 'history-3h'].includes(timelapse)) {
    const durationInMinutes: number = Math.ceil(rotationTo - rotationFrom);
    if (durationInMinutes <= maxFramesCount) {
      return { speed: 120, step: 1 };
    } else {
      const sparseMinutes: number = durationInMinutes - sparseFramesThreshold;
      const sparseStubCount: number = maxFramesCount - sparseFramesThreshold;
      const evenStep: number = Math.floor(sparseMinutes / sparseStubCount);
      const mod: number = sparseMinutes % sparseStubCount;
      // For frames before sparseFramesThreshold, step is 1;
      // For frames after sparseFramesThreshold, step is sparse, `evenStep`;
      // For the very last `mod` frames, step is `evenStep + 1`;
      const step: [number, number[]] = [sparseFramesThreshold, [evenStep, mod]];

      return { speed: 120, step };
    }
  }

  return {
    '2m': { speed: 60, step: 1 },
    '10m': { speed: 80, step: 1 },
    '1h': { speed: 240, step: 2 },
    '3h': { speed: 360, step: 3 },
    '6h': { speed: 720, step: 6 },
    '12h': { speed: 1440, step: 12 },
    '24h': { speed: 2880, step: 24 },
    'history-oz': { speed: 30, step: 1 },
  }[timelapse];
};

export const cameraPeriod: number = 60000;

export function mod(x: number, m: number): number {
  return ((x % m) + m) % m;
}

export function getPlayingTime(panoCanvas: PanoCanvas, panoData: PanoData[] | OpticalZoomData[]): string {
  const f: number = panoCanvas?.getCurrentValue();
  const time: number = _get(panoData, `${f}.1`, 0) * 1000;
  const playingTime: string = time ? dayjs(time).format('YYYY-MM-DD HH:mm') : dayjs().format('YYYY-MM-DD HH:mm');
  return playingTime;
}

/**
 *
 * @param event - The snake_case name of the event we want to track in mixpanel
 * @param parameters - Any additional information we want to include in the event
 * @param user - Info about the user we want included with the event
 */
export function logEvent(event: string, parameters = {}, user = {}): void {
  TRACKING_ENABLED && mixpanel.track(event, { ...parameters, ...user });
}

/**
 * Asserts that value is defined. If not, throws an error.
 * @note This should only happen when there is something wrong with the data.
 * @param variable
 * @param value
 */
export function assertValueDefined(variable: string, value: number | string | object): void {
  if (value === undefined || value === null) {
    throw new Error(`[Error] ${variable} is undefined or null`);
  }
}
