import { useCallback } from 'react';
import { INCIDENT_LIMIT } from 'config/constants';
import { rsaIsFullAuth, rscIncidentLoadingStart, rsiUserIncidents, rsiUserIncidentsTs } from 'data';
import { IncidentApis } from 'data/proxyApi';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { GetIncidentsQueryParams, Incident } from 'types';
import { applyArrayDifference } from 'utils/array';
import { getIncidentLabel } from 'utils/incident';
import { nowMs } from 'utils/moment';

interface Returned {
  /**
   * Fetching all incidents once
   * - Use Case: Fetch incidents on user interaction or on page load
   */
  fetchIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;
  /**
   * Fetching all incidents once, and append them to any incidents in the store.
   * - Use Case: Fetch incidents on user interaction or on page load
   */
  fetchAndAppendIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;
  /**
   * Fetching additional incidents
   * - Use Case: Fetch additition incidents when polling
   */
  refreshIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;

  getIncidentById?: (_id: string | number, _forceFetch?: boolean, _syncToStore?: boolean) => Promise<Incident>;
}

export default function useIncidents(): Returned {
  const [userIncidents, setUserIncidents] = useRecoilState(rsiUserIncidents);
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const [userIncidentsTs, setUserIncidentsTs] = useRecoilState(rsiUserIncidentsTs);
  const setIncidentLoadingStart = useSetRecoilState(rscIncidentLoadingStart);

  /**
   * Used to fetch incidents once
   * - NOTE: This replaces the all the incidents in the store
   */
  const fetchIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status } = {}) => {
      const isNoFilterEnabled = status?.length === 1 && status?.[0] === 'prescribed';

      if (!isFullAuth) {
        return [];
      } else if (isNoFilterEnabled) {
        setUserIncidentsTs(nowMs());
        setUserIncidents([]);
        return [];
      }

      setIncidentLoadingStart(Date.now());
      const incidents = await IncidentApis.apiGetIncidents({ limit, keyword, status, stationId });

      setUserIncidents(incidents);
      setUserIncidentsTs(nowMs());

      return incidents;
    },
    [isFullAuth, setIncidentLoadingStart, setUserIncidents, setUserIncidentsTs],
  );

  /**
   * Used to fetch incidents and add them to the store
   * - NOTE: This keeps the existing incidents in the store
   */
  const fetchAndAppendIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status } = {}) => {
      const isNoFilterEnabled = status?.length === 1 && status?.[0] === 'prescribed';
      if (!isFullAuth) {
        return [];
      } else if (isNoFilterEnabled) {
        setUserIncidentsTs(nowMs());
        setUserIncidents([]);
        return [];
      }

      setIncidentLoadingStart(Date.now());
      const incidents = await IncidentApis.apiGetIncidents({ limit, keyword, status, stationId });
      setUserIncidents((userIncidents) => applyArrayDifference<Incident>(userIncidents, incidents));

      setUserIncidentsTs(nowMs());

      return incidents;
    },
    [isFullAuth, setIncidentLoadingStart, setUserIncidents, setUserIncidentsTs],
  );

  /**
   * Used for polling for new incidents
   * Note: this keeps the existing incidents in the store.
   * If the queryParams only request possible or confirmed status,
   * existing incidents that have been closed or dismissed will remain in the store.
   * Skips generic error handling
   */
  const refreshIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status } = {}) => {
      const isNoFilterEnabled = status?.length === 1 && status?.[0] === 'prescribed';
      if (!isFullAuth || isNoFilterEnabled) {
        return [];
      }
      const diffIncidents = await IncidentApis.apiGetIncidents({
        ifRange: userIncidentsTs,
        limit,
        keyword,
        stationId,
        shouldSkipErrorHandling: true,
      });

      let incidents = applyArrayDifference(userIncidents, diffIncidents);
      if (status) {
        incidents = incidents.filter((incident) => {
          return status.includes(getIncidentLabel(incident));
        });
      }

      setUserIncidents(incidents);
      setUserIncidentsTs(nowMs());

      return incidents;
    },
    [isFullAuth, setUserIncidents, setUserIncidentsTs, userIncidents, userIncidentsTs],
  );

  const getIncidentById: (_id: string | number, _forceFetch?: boolean, _syncToStore?: boolean) => Promise<Incident> =
    useCallback(
      async (id, forceFetch = false, syncToStore = true) => {
        if (!forceFetch) {
          const incident = userIncidents.find((i) => i.id === id);
          if (incident) {
            return incident;
          }
        }

        const incident = await IncidentApis.apiGetIncidentById(id);
        if (syncToStore) {
          const incidents = applyArrayDifference<Incident>(userIncidents, [incident]);
          setUserIncidents(incidents);
        }
        return incident;
      },
      [setUserIncidents, userIncidents],
    );

  return {
    fetchIncidents,
    fetchAndAppendIncidents,
    refreshIncidents,
    getIncidentById,
  };
}
