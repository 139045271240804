/**
 * This file is for managing authentication
 * authentication - Confirms users are who they say they are
 * - Restricts front-end content based on their profile
 */

import { orgIsPge } from 'componentLibrary/Formik/validationSchema';
import { SECRET_ANONYMOUS, USER_PAGE_WITH_FULL_AUTH_FROZEN } from 'config/base';
import { localStorageEffect, rspIsStationInOz } from 'data';
import { rsiSelectedStationId } from 'data/store/incidentStore';
import { rsiIncidentLabelById } from 'data/store/incidentStore/incidentById';
import { rssIsStationOzCapable, rssIsStationOzRequestableById, rssQueryStationById } from 'data/store/stationStore';
import _intersection from 'lodash/intersection';
import _some from 'lodash/some';
import _uniq from 'lodash/uniq';
import { atom, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import {
  exactTrackedUser,
  INCIDENT_LABEL,
  ORG_TYPES,
  OrgInfo,
  PanoUser,
  Permission,
  RsaHasPermissionParams,
  TrackedUser,
} from 'types';

import { rsaUser, rsaUserId, rsaUserOrg, rsaUserOrgs } from '../user/user';

/**
 * Whether an org has access to powerlines
 */
export const rsaOrgWithPowerLines = selector<boolean>({
  key: 'rsaOrgWithPowerLines',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    const orgs: OrgInfo[] = user?.orgs || [];
    return !!orgs.find((org) => [ORG_TYPES.PANO, ORG_TYPES.UTILITY].includes(org.type));
  },
});

/**
 * Whether an org has access to SCFT forests
 */
export const rsaOrgWithSCFTForestsOnMap = selector<boolean>({
  key: 'rsaOrgWithSCFTForestsOnMap',
  get: ({ get }) => {
    const org: OrgInfo = get(rsaUserOrg);
    return org && ['AU NSW SCFT', 'Pano Sales', 'Pano Sales AU All Stations'].includes(org?.name);
  },
});

/**
 * Whether an org with FCNSW forests
 */
export const rsaOrgWithFCNSWForestsOnMap = selector<boolean>({
  key: 'rsaOrgWithFCNSWForestsOnMap',
  get: ({ get }) => {
    const org: OrgInfo = get(rsaUserOrg);
    return org && ['AU NSW FCNSW', 'Pano Sales', 'Pano Sales AU All Stations'].includes(org?.name);
  },
});

/**
 * Returns whether the user belongs to a SFM org
 */
export const rsaIsUserOrgSFM = selector<boolean>({
  key: 'rsaIsUserOrgSFM',
  get: ({ get }) => {
    const orgs = get(rsaUserOrgs);

    return !!orgs?.find((org) =>
      ['AU SFM', 'AU SFM Notifications', 'AU SFM-TAS', 'AU NF', 'TAS RFF', 'TAS TFS'].includes(org.name),
    );
  },
});

/**
 * Returns whether the user belongs to a Forico org
 */
export const rsaIsUserOrgForico = selector({
  key: 'rsaIsUserOrgForico',
  get: ({ get }) => {
    const orgs = get(rsaUserOrgs);

    return !!orgs?.find((org) => ['TAS FORICO'].includes(org.name));
  },
});

/**
 * Returns whether the user has visibility into the TFS Brigade Boundary
 */
export const rsaIsUserTFSBrigade = selector({
  key: 'rsaIsUserTFSBrigade',
  get: ({ get }) => {
    const orgs = get(rsaUserOrgs);

    return !!orgs?.find((org) =>
      ['TAS FORICO', 'TAS TASMANIA FIRE SERVICE', 'TAS STTAS', 'TAS TPWS', 'TAS RFF'].includes(org.name),
    );
  },
});

/**
 * Returns whether the user belongs to an org that can access the forico 50k layer
 */
export const rsaIsUserOrgForico50k = selector({
  key: 'rsaIsUserOrgForico50k',
  get: ({ get }) => {
    const orgs = get(rsaUserOrgs);

    return !!orgs?.find((org) =>
      ['TAS FORICO', 'TAS TASMANIA FIRE SERVICE', 'TAS STTAS', 'TAS TPWS', 'TAS RFF'].includes(org.name),
    );
  },
});

/**
 * Returns whether the user belongs to AU SA GTFA organization
 */
export const rsaIsUserOrgGreenTriangle = selector<boolean>({
  key: 'rsaIsUserOrgGreenTriangle',
  get: ({ get }) => {
    const orgs: OrgInfo[] = get(rsaUserOrgs);

    return !!orgs?.find((org) =>
      [
        'AU GTFA',
        'AU GTFA Notifications',
        'SA DEW',
        'SA CFS',
        'AU TPPL',
        'AU SFM',
        'AU PFO',
        'AU OFO',
        'VIC HVP',
        'VIC HVP Notifications',
        'VIC HVP-GT',
        'AU GTFP',
        'VIC GPFL',
        'AU AKD',
        'AU ABP',
        'VIC CFA-GT',
        'VIC CFA-D4',
        'VIC CFA-D5',
        'VIC CFA-D17',
        'VIC FFMV-GT',
        'VIC FFMV-WIM',
        'VIC FFMV-FSW',
        'SA CFS-GT',
        'SA CFS-GT Notifications',
        'AU SFM-GT',
      ].includes(org.name),
    );
  },
});

/**
 * Returns whether the user belongs to AU SA GTFA organization
 */
export const rsaIsUserHVP = selector({
  key: 'rsaIsUserHVP',
  get: ({ get }) => {
    const orgs: OrgInfo[] = get(rsaUserOrgs);

    return !!orgs?.find((org) =>
      ['VIC HVP', 'VIC HVP-DWF', 'VIC DWF', 'VIC FFMV', 'VIC CFA', 'VIC HVP Notifications'].includes(org.name),
    );
  },
});

/**
 * Whether the use has access to RFS bounds
 */
export const rsaOrgWithRfsBoundsOnMap = selector<boolean>({
  key: 'rsaOrgWithRfsBoundsOnMap',
  get: ({ get }) => {
    const org: OrgInfo = get(rsaUserOrg);
    return (
      org &&
      [
        "Sean's ECs",
        'AU NSW SCFT',
        'Sales',
        'Mission Roofs',
        'AU FSCW',
        'AU NSW RFS Riverina',
        'AU NSW FCNSW',
        'Pano Sales AU All Stations',
      ].includes(org?.name)
    );
  },
});

/**
 * Whether an org has PGE Assets
 */
export const rsaIsUserOrgPge = selector<boolean>({
  key: 'rsaIsUserOrgPge',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return !!(user?.orgs || []).find((org) => orgIsPge(org.name));
  },
});

/**
 * Whether the user's first org is `Pano` type
 */
export const rsaOrgIsPanoType = selector<boolean>({
  key: 'rsaOrgIsPanoType',
  get: ({ get }) => {
    const org: OrgInfo = get(rsaUserOrg);
    return org && org?.type === ORG_TYPES.PANO;
  },
});

/**
 * Whether any of the users orgs is `Pano` type
 */
export const rsaSomeOrgIsPanoType = selector<boolean>({
  key: 'rsaSomeOrgIsPanoType',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    const orgs: OrgInfo[] = user?.orgs || [];
    return !!orgs.find((org) => org?.type === ORG_TYPES.PANO);
  },
});

/**
 * Returns the tracked user, which is used by our analytics tools
 */
export const rsaTrackedUser = selector<TrackedUser>({
  key: 'rsaTrackedUser',
  get: ({ get }) => {
    const userId: string = get(rsaUserId);
    const userOrg: OrgInfo = get(rsaUserOrg);
    return userId && userOrg?.name && userOrg.type
      ? exactTrackedUser({
          user_id: userId || '',
          user_org_name: userOrg?.name || '',
          user_org_type: userOrg?.type || '',
        })
      : null;
  },
});

export const rsaIsSystemSuper = selector<boolean>({
  key: 'rsaIsSystemSuper',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return user?.super;
  },
});

/**
 * @Returns whether or not the user has the given permission, and the ability to access the station
 */
export const rsaHasPermission: (_params: RsaHasPermissionParams) => RecoilValueReadOnly<boolean> = selectorFamily<
  boolean,
  RsaHasPermissionParams
>({
  key: 'rsaHasPermission',
  get:
    ({ permission, stations }: RsaHasPermissionParams) =>
    ({ get }): boolean => {
      const isSystemSuper = get(rsaIsSystemSuper);

      if (isSystemSuper) {
        return true;
      }
      const user = get(rsaUser);

      if (stations) {
        const orgsId = (user?.orgs || []).map((org) => org.id);
        // org ids in all given stations
        const stationOrgIds = _uniq(
          stations.reduce((ids: string[], station) => {
            return [...ids, ...(station?.orgs || [])];
          }, []),
        );
        // intersect the station orgs with current account orgs
        const sameOrgIds = _intersection(orgsId, stationOrgIds);

        if (sameOrgIds.length > 0) {
          const sameOrgs: OrgInfo[] = sameOrgIds.map((id) => user?.orgs.find((org) => org.id === id));

          // At least one org with target permission
          return _some(sameOrgs, (org) => (org?.orgPermissions || []).includes(permission));
        }
        return false;
      }

      return _some(user?.orgs || [], (org) => (org?.orgPermissions || []).includes(permission));
    },
});

/**
 * Whether the user is logged in by access token
 * - This means the user went to a shared page, and the user isn't logged int
 */
export const rsaIsLoggedInByToken: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaIsLoggedInByToken',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return !!user?.bearer && user?.firstName === SECRET_ANONYMOUS;
  },
});

/**
 * Whether a user is authenticated with a username/password
 */
export const rsaIsFullAuth: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaIsFullAuth',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return !!user?.bearer && user?.firstName !== SECRET_ANONYMOUS;
  },
});

/**
 * This is the url of the shared page a user visited that caused permissions to be frozen
 * - **frozen** - Visiting shared incident for an org that this user doesnt belong to
 * - If empty user is not on a frozen page
 */
export const rsaPageWithFullAuthFrozen = atom<string>({
  key: 'rsaPageWithFullAuthFrozen',
  default: '',
  effects: [localStorageEffect<string>(USER_PAGE_WITH_FULL_AUTH_FROZEN, null)],
});

/**
 * Returns whether the user has permission to control OZ for the given station ID
 */
export const rsaHasOzAccessByStationId = selectorFamily<boolean, number>({
  key: 'rsaHasOzAccessByStationId',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));

      const hasOzAccessToStation = get(
        rsaHasPermission({
          stations: [station],
          permission: Permission.OzController,
        }),
      );

      return hasOzAccessToStation;
    },
});

/**
 * Returns whether the user is able to request OZ for the given incidentId for the selected station
 */
export const rsaIsRequestOZEnabledByIncidentId = selectorFamily<boolean, number>({
  key: 'rsaIsRequestOZEnabledByIncidentId',
  get:
    (incidentId) =>
    ({ get }) => {
      if (!incidentId) {
        return false;
      }

      const stationId = get(rsiSelectedStationId);
      const label = get(rsiIncidentLabelById(incidentId));

      const isConfirmedIncident = label === INCIDENT_LABEL?.CONFIRMED;
      const hasOzAccessToStation = get(rsaHasOzAccessByStationId(stationId));
      const isStationOzCapable = get(rssIsStationOzCapable(stationId));
      const isStationInOz = get(rspIsStationInOz(stationId));
      const isStationOzRequestableById = get(rssIsStationOzRequestableById(stationId));

      return (
        isConfirmedIncident &&
        !hasOzAccessToStation &&
        !isStationInOz &&
        isStationOzCapable &&
        isStationOzRequestableById
      );
    },
});
