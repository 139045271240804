import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  'page-container': {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  'layout-container': {
    flexGrow: 1,
  },
  'alert-content': {
    marginTop: 3,
  },
};

export default styles;
