import ResClosed from 'assets/icons/res-closed.svg';
import ResConfirmed from 'assets/icons/res-confirmed.svg';
import ResDismissed from 'assets/icons/res-dismissed.svg';
import ResPrescribed from 'assets/icons/res-prescribed.svg';
import ResUnresolved from 'assets/icons/res-unresolved.svg';
import { DEFAULT_PLAYER_PERIOD, INCIDENT_ORDERS } from 'config/constants';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import {
  Incident,
  INCIDENT_LABEL,
  INCIDENT_LABEL_OPTIONS,
  INCIDENT_TYPE_OPTIONS,
  IncidentCamera,
  IncidentFilterOptions,
  IncidentLabel,
  LonLat,
  Mark,
  Station,
  StationsMap,
} from 'types';
import { cameraPeriod, timelapseToSecond } from 'utils/common';
import { incidentResolutionTitle } from 'utils/map';
import { scaleNumberTo } from 'utils/number';
import { getStateNameFromAbbr } from 'utils/place';

export const hasLngLat = (location: Incident | Station | LonLat): boolean => {
  return location && !!location.lon && !!location.lat;
};

export const hasNoLngLat = (incident: Incident): boolean => !hasLngLat(incident);

export const shouldTriangulate = (incident: Incident): boolean => {
  return !!(
    incident &&
    !hasLngLat(incident) &&
    incident.cameras &&
    incident.cameras.length === 1 &&
    incident.cameras[0].mark
  );
};

export const hasTriangulated = (incident: Incident): boolean =>
  (incident?.cameras || []).filter((c) => !!c.mark).length > 1;

export const scaleMarkForApi = (mark: Mark): Mark => ({
  image: {
    x: scaleNumberTo(mark.image.x, 0),
    y: scaleNumberTo(mark.image.y, 0),
  },
  canvas: {
    x: scaleNumberTo(mark.canvas.x, 0),
    y: scaleNumberTo(mark.canvas.y, 0),
    z: scaleNumberTo(mark.canvas.z, 5),
  },
});

/**
 * Check whether a label is inactive or not.
 * @param label
 */
export const isLabelInactive = (label: IncidentLabel): boolean =>
  [INCIDENT_LABEL.CLOSED, INCIDENT_LABEL.DISMISSED].includes(label);

export const isIncidentInActive = (incident: Incident): boolean => isLabelInactive(getIncidentLabel(incident));

export const isIncidentActive = (incident: Incident): boolean => incident && !isIncidentInActive(incident);

export const canMarkFire = (incident: Incident): boolean => !incident || isIncidentActive(incident);

/**
 * An incident that is “dismissed”: closedTime != null && label == null
 * An incident that is “closed”: closedTime != null
 * @param incident
 */
export const getIncidentLabel = (incident: Incident): IncidentLabel => {
  const label = incident?.label || '';
  if (!label && !!incident?.closeTime) {
    return INCIDENT_LABEL.DISMISSED;
  }

  if (incident?.closeTime) {
    return INCIDENT_LABEL.CLOSED;
  }

  return INCIDENT_LABEL[label.toUpperCase()] || INCIDENT_LABEL.POSSIBLE;
};

export const getIncidentIconByLabel = (label: IncidentLabel): string =>
  ({
    [INCIDENT_LABEL.DISMISSED]: ResDismissed,
    [INCIDENT_LABEL.CLOSED]: ResClosed,
    [INCIDENT_LABEL.PRESCRIBED]: ResPrescribed,
    [INCIDENT_LABEL.POSSIBLE]: ResUnresolved,
    [INCIDENT_LABEL.CONFIRMED]: ResConfirmed,
  }[label] || ResUnresolved);

export const incidentFilterOptions: IncidentFilterOptions = {
  types: [
    INCIDENT_TYPE_OPTIONS.VEGETATION,
    INCIDENT_TYPE_OPTIONS.STRUCTURE,
    INCIDENT_TYPE_OPTIONS.CAR,
    INCIDENT_TYPE_OPTIONS.PRESCRIBED,
    INCIDENT_TYPE_OPTIONS.CONTROLLED,
  ],
  labels: [
    INCIDENT_LABEL_OPTIONS.POSSIBLE,
    INCIDENT_LABEL_OPTIONS.CONFIRMED,
    INCIDENT_LABEL_OPTIONS.CLOSED,
    INCIDENT_LABEL_OPTIONS.DISMISSED,
  ],
};

export const getRotationForIncident = (incident: Incident, timeLapse: string, startTimeMs = 0): [number, number] => {
  const incidentInActive: boolean = isIncidentInActive(incident);
  const period: number = cameraPeriod;
  if (timeLapse === 'incident') {
    const rotationFrom: number = incident.startTime - DEFAULT_PLAYER_PERIOD;
    const rotationTo: number = incident.endTime / 60 || Date.now() / period;
    return [Math.floor(rotationFrom / 60), Math.ceil(rotationTo)];
  }

  if (timeLapse === 'history') {
    // @todo the `rotationFrom` time should be the optical-zoom section start time.
    const now: number = Date.now();
    const rotationFrom: number = Math.floor((now - DEFAULT_PLAYER_PERIOD * 1000) / period) - 1;
    const rotationTo: number = now / period;
    return [rotationFrom, rotationTo];
  }

  if (timeLapse === 'history-oz') {
    return [Math.floor(startTimeMs), 0];
  }

  if (timeLapse === 'history-3h') {
    const rotationFrom: number = incident.startTime - 3 * 60 * 60;
    const rotationTo: number = incident.endTime / 60 || Date.now() / period;
    return [Math.floor(rotationFrom / 60), Math.ceil(rotationTo)];
  }

  // If incident is closed or dismissed.
  if (incidentInActive) {
    const rotationFrom: number = incident.startTime;
    if (timeLapse === '1h') {
      // For `1h`, always get 1 hour from start time
      // @see https://panoai.atlassian.net/browse/CAM-2406
      const rotationTo: number = rotationFrom + 60 * 60;
      return [Math.floor(rotationFrom / 60), Math.ceil(rotationTo / 60)];
    }

    if (timeLapse === '12h') {
      // For `3h`, always get 3 hours from start time
      // @see https://panoai.atlassian.net/browse/CAM-2589
      const rotationTo: number = rotationFrom + 60 * 60 * 12;
      return [Math.floor(rotationFrom / 60), Math.ceil(rotationTo / 60)];
    }

    const rotationTo: number = rotationFrom + DEFAULT_PLAYER_PERIOD;
    return [Math.floor(rotationFrom / 60), Math.ceil(rotationTo / 60)];
  } else {
    const now: number = Date.now();
    const rotationFrom: number = Math.floor((now - timelapseToSecond[timeLapse] * 1000) / period) - 1;
    const rotationTo: number = Math.ceil(now / period);
    return [rotationFrom, rotationTo];
  }
};

export const stringifyIncident = (incident: Incident): string => {
  return [
    incident.id,
    incident.name,
    incident.place?.state,
    getStateNameFromAbbr(incident.place?.state),
    incident.place?.city,
    incident.place?.county,
    incidentResolutionTitle[getIncidentLabel(incident)],
  ]
    .filter((f) => f || '')
    .join(' ')
    .toLowerCase();
};

export const hitIncident = (incident: Incident, queryStationById: (_id: number) => Station, query: string): boolean => {
  const incidentStr: string = [
    incident.stringified || stringifyIncident(incident),
    (incident.cameras || [])
      .map((c) => queryStationById(c.id))
      .map((c) => (c ? c?.name : ''))
      .join(' '),
  ]
    .join(' ')
    .toLowerCase();

  return incidentStr.indexOf(String(query).toLowerCase()) >= 0;
};

export const getIncidentTypeTextColor = (label: IncidentLabel): string => {
  switch (label) {
    case INCIDENT_LABEL.DISMISSED || INCIDENT_LABEL.PRESCRIBED || INCIDENT_LABEL.CLOSED:
      return 'greys.light';
    case INCIDENT_LABEL.CONFIRMED:
      return 'secondary.main';
    default:
      return 'primary.main';
  }
};

export const getIncidentCamera = (
  incident: Incident,
  selectedCameraId = 0,
  stationsMap: StationsMap,
): IncidentCamera => {
  if (!incident || !stationsMap) {
    return null;
  }

  // If trying to get a selected incident camera, and it exists, return it.
  if (selectedCameraId) {
    const incidentCamera: IncidentCamera = _find(incident?.cameras, { id: selectedCameraId });
    const stationExists: boolean = !!incidentCamera && !!stationsMap[selectedCameraId];
    if (stationExists) {
      return incidentCamera;
    }
    return null;
  }

  // If no specified incident camera, return the `primary`
  const primaryIncidentCamera: IncidentCamera = _find(incident?.cameras, { id: incident.primary });
  const stationExists: boolean = primaryIncidentCamera && !!stationsMap[primaryIncidentCamera.id];
  if (stationExists) {
    return primaryIncidentCamera;
  }

  // Get the first existing incident camera and return it.
  for (let i: number = 0; i < incident?.cameras?.length; i++) {
    const incidentCamera: IncidentCamera = incident?.cameras[i];
    const stationExists: boolean = incidentCamera && !!stationsMap[incidentCamera.id];
    if (stationExists) {
      return incidentCamera;
    }
  }

  return null;
};

// @note change incident IN PLACE. Object reference is kept.
export const processIncidentFromApi = (incident: Incident): void => {
  incident.order = INCIDENT_ORDERS[getIncidentLabel(incident)];
  incident.cameras = _sortBy(incident.cameras || [], ['mark.time', 'distance']);
  incident.stringified = stringifyIncident(incident);
};
