/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, lazy, LazyExoticComponent } from 'react';

interface CustomRoute {
  path: string;
  component: LazyExoticComponent<ComponentType>;
}

const PanoInvite = lazy((): any => import('pages/Invite/PanoInvite'));
const CommunityInvite = lazy((): any => import('pages/Invite/CommunityInvite'));
const MapViewOLD = lazy((): any => import('pages/MapViewOLD'));
const IncidentDetail = lazy((): any => import('pages/IncidentDetail'));
const StationDetail = lazy((): any => import('pages/StationDetail'));
const UpdateIncidentStatusPage = lazy((): any => import('pages/UpdateIncidentStatus'));
const PanoList = lazy(() => import('pages/PanoList'));
const AccountSettings = lazy((): any => import('pages/Auth/Settings'));

export const PRIVATE_ROUTES_OLD: CustomRoute[] = [
  {
    path: '/invite',
    component: PanoInvite,
  },
  {
    path: '/community',
    component: CommunityInvite,
  },
  {
    path: '/incident/:id',
    component: IncidentDetail,
  },
  {
    path: '/incident/:id/edit',
    component: UpdateIncidentStatusPage,
  },
  {
    path: '/i/:token?',
    component: IncidentDetail,
  },
  {
    path: '/station/:id',
    component: StationDetail,
  },
  {
    path: '/map/stations/panos/:pageNo?',
    component: PanoList,
  },
  {
    path: '/map/:page?/:view?/:pageNo?',
    component: MapViewOLD,
  },
  {
    path: '/',
    component: MapViewOLD,
  },
  {
    path: '/account/settings',
    component: AccountSettings,
  },
];
