import { countryPhones } from 'config/base';
import _get from 'lodash/get';
import proj4 from 'proj4';
import { GDACoordinates, Incident, IncidentCamera, LonLat, LonLatArray, PhoneCode, Station } from 'types';

/**
 * Get the country code from the country name.
 * @param country
 */
export const getCountryCodeFromCountry = (country: string): string => {
  const countryPhone: PhoneCode = _get(countryPhones, country.toLowerCase(), countryPhones['us']);
  return countryPhone.code;
};

/**
 * In form, a non-null phone with value just country code is considered empty.
 * @param phone
 */
export const phoneIsEmpty = (phone: string): boolean => {
  return !phone || !!Object.values(countryPhones).find((countryPhone) => countryPhone.code === phone);
};

// Camera can see 36 degree from the bearing (number is arbitrary).
export const CAMERA_IN_VIEW_CONE_ANGLE: number = 36;
// Error bounding range of the bearing
export const CAMERA_UNCERTAINTY_ANGLE: number = 0.5;

// Get angle based on mapzoom level.
// @todo remove the magic number.
export const getCameraUncertaintyAngleByMapZoom = (mapZoom: number): number => {
  if (mapZoom > 16) {
    return 0.001;
  }

  if (mapZoom > 13.5) {
    return 0.01;
  }

  if (mapZoom > 11) {
    return 0.1;
  }

  return CAMERA_UNCERTAINTY_ANGLE;
};

/**
 * 度换成弧度
 * @param  {Float} d  度
 * @return {[Float}   弧度
 */
export function rad(d: number): number {
  return (d * Math.PI) / 180.0;
}
/**
 * 弧度换成度
 * @param  {Float} x 弧度
 * @return {Float}   度
 */
export function deg(x: number): number {
  return (x * 180) / Math.PI;
}
/**
 *
 * @param {*} lng longitude
 * @param {*} lat latitude
 * @param {*} brng angle   ---- north：000°或360°  east：090° south：180°  west：270°
 * @param {*} dist distance 9000
 *
 */
export function getLonAndLat(lng: number, lat: number, brng: number, dist: number): LonLat {
  //大地坐标系资料WGS-84 长半径a=6378137 短半径b=6356752.3142 扁率f=1/298.2572236
  const a: number = 6378137;
  const b: number = 6356752.3142;
  const f: number = 1 / 298.257223563;

  const lon1: number = lng * 1;
  const lat1: number = lat * 1;
  const s: number = dist;
  const alpha1: number = rad(brng);
  const sinAlpha1: number = Math.sin(alpha1);
  const cosAlpha1: number = Math.cos(alpha1);

  const tanU1: number = (1 - f) * Math.tan(rad(lat1));
  const cosU1: number = 1 / Math.sqrt(1 + tanU1 * tanU1),
    sinU1: number = tanU1 * cosU1;
  const sigma1: number = Math.atan2(tanU1, cosAlpha1);
  const sinAlpha: number = cosU1 * sinAlpha1;
  const cosSqAlpha: number = 1 - sinAlpha * sinAlpha;
  const uSq: number = (cosSqAlpha * (a * a - b * b)) / (b * b);
  const A: number = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  const B: number = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

  let sigma: number = s / (b * A),
    sigmaP: number = 2 * Math.PI;
  let cos2SigmaM: number, sinSigma: number, cosSigma: number;
  while (Math.abs(sigma - sigmaP) > 1e-12) {
    cos2SigmaM = Math.cos(2 * sigma1 + sigma);
    sinSigma = Math.sin(sigma);
    cosSigma = Math.cos(sigma);
    const deltaSigma: number =
      B *
      sinSigma *
      (cos2SigmaM +
        (B / 4) *
          (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
            (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
    sigmaP = sigma;
    sigma = s / (b * A) + deltaSigma;
  }

  const tmp: number = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
  const lat2: number = Math.atan2(
    sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
    (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp),
  );
  const lambda: number = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
  const C: number = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
  const L: number =
    lambda -
    (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

  //   var revAz = Math.atan2(sinAlpha, -tmp); // final bearing

  return { lon: lon1 + deg(L), lat: deg(lat2) };
}

function roundAngle(angle: number): number {
  return (angle + 360) % 360;
}

function lngLatToArray(lngLat: { lon: number; lat: number }): LonLatArray {
  return [lngLat.lon, lngLat.lat];
}

// Polygon points to draw Incident Direction Line
export function getRectPointsLatLng(
  incident: Incident,
  station: Station,
  incidentCamera: IncidentCamera,
  zoom: number,
): LonLatArray[] {
  // @todo hacky magic number to adjust rect width under different zoom levels
  const rectWidth: number = (zoom > 10 ? 800000 : 600000) / Math.pow(2, zoom);
  const distance: number = _get(incidentCamera, 'cameras.0.visibility', 20) * 1000;
  const { bearing }: IncidentCamera = incidentCamera;
  const { lat: cameraLat, lon: cameraLng }: Station = station;
  if (bearing === null) {
    console.warn('No bearing found');
  }

  const point1Bearing: number = roundAngle(bearing + 90);
  const point2Bearing: number = roundAngle(bearing - 90);
  const point1: LonLat = getLonAndLat(cameraLng, cameraLat, point1Bearing, rectWidth);
  const point2: LonLat = getLonAndLat(cameraLng, cameraLat, point2Bearing, rectWidth);
  const point3: LonLat = getLonAndLat(point2.lon, point2.lat, bearing, distance);
  const point4: LonLat = getLonAndLat(point1.lon, point1.lat, bearing, distance);
  return [point1, point2, point3, point4, point1].map(lngLatToArray);
}

// Polygon points to draw Incident Uncertainty Cone and Incident Direction Line
// distance from database is in kilometers (metric)
// IncidentCamera composes of object attributes from the Stations (`/ecs`) and selected incident camera.
export function getPolygonPointsLatLng(
  incident: Incident,
  incidentCamera: IncidentCamera,
  station: Station,
  coneAngle = CAMERA_IN_VIEW_CONE_ANGLE,
  canvasBearing: number = null,
): LonLatArray[] {
  const halfAngleOfAreaInView: number = coneAngle / 2;
  const visibility: number = _get(incidentCamera, 'cameras.0.visibility', 20) * 1000;
  // @doc https://juejin.cn/post/6844904179387858951
  const distance: number = visibility / Math.cos(((2 * Math.PI) / 360) * halfAngleOfAreaInView);

  const { lat: cameraLat, lon: cameraLng } = station;
  const bearingAngle: number = canvasBearing || incidentCamera?.bearing;

  return [
    { lat: cameraLat, lon: cameraLng },
    getLonAndLat(cameraLng, cameraLat, roundAngle(bearingAngle - halfAngleOfAreaInView), distance),
    getLonAndLat(cameraLng, cameraLat, roundAngle(bearingAngle + halfAngleOfAreaInView), distance),
    { lat: cameraLat, lon: cameraLng },
  ].map(lngLatToArray);
}

export function getCameraCircleLatLng(cam: Station): number[][] {
  // @todo always use the visibility from the first camera of a station.
  const distance: number = ((_get(cam, 'cameras.0.visibility') as number) || 10) * 1610;
  const { lat: cameraLat, lon: cameraLng } = cam;
  const area: [number, number][] = [];
  for (let angle: number = 0; angle < 360; angle += 1) {
    const point: LonLat = getLonAndLat(cameraLng, cameraLat, angle, distance);
    area.push([point.lon, point.lat]);
  }
  const _point: LonLat = getLonAndLat(cameraLng, cameraLat, 0, distance);
  area.push([_point.lon, _point.lat]);
  return area;
}

export function getGDACoordinatesFromLonLat(lonLat: LonLat): GDACoordinates {
  const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
  const utmZone = Math.floor((lonLat.lon + 180) / 6) + 1;
  const gda2020Projection = `+proj=utm +zone=${utmZone} +south +datum=GDA2020 +units=m +no_defs`;

  const gda2020Coordinates = proj4(wgs84Projection, gda2020Projection, [lonLat.lon, lonLat.lat]);

  const roundedGDA2020Coordinates: GDACoordinates = {
    easting: Math.round(gda2020Coordinates[0]),
    northing: Math.round(gda2020Coordinates[1]),
    zone: utmZone,
  };

  return roundedGDA2020Coordinates;
}
