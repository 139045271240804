import { ActivityApis } from 'data/proxyApi';
import { rsactCurrentActivity } from 'data/store/activityStore';
import { useRecoilState } from 'recoil';
import { Activity } from 'types';

interface Returned {
  /**
   * Either Creates an activity if one doesnt exist
   * Or extends the current activity if it does
   */
  createOrExtendActivity?: () => Promise<Activity>;
  /**
   * Creates a new activity for the user
   */
  createActivity?: () => Promise<Activity>;
  /**
   * Extends the life of the current activity
   * - If no activ
   */
  extendActivity?: () => Promise<Activity>;
  /**
   * Ends the activity of the current activity
   */
  endActivity?: () => Promise<Activity>;
}

export default function useActivity(): Returned {
  const [activity, setActivity] = useRecoilState(rsactCurrentActivity);

  const createOrExtendActivity = () => {
    if (activity?.id) {
      return extendActivity();
    }
    return createActivity();
  };

  const createActivity = async () => {
    const newActivity = await ActivityApis.apiCreateActivity();
    setActivity(newActivity);

    return newActivity;
  };

  const extendActivity = async () => {
    const newActivity = await ActivityApis.apiExtendActivityById(activity?.id);
    setActivity(newActivity);

    return newActivity;
  };

  const endActivity = async () => {
    const newActivity = await ActivityApis.apiEndActivityById(activity?.id);
    setActivity(null);

    return newActivity;
  };

  return {
    createActivity,
    createOrExtendActivity,
    endActivity,
    extendActivity,
  };
}
