/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { lazy, Suspense } from 'react';
import FeatureFlag, { Feature } from 'componentLibrary/FeatureFlag';
import Loading from 'componentLibrary/Loading';
import SSOCallback from 'pages/Auth/SSOCallback';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { useSetSentryUser, useUpdateIsOnFrozenPage } from './Router.helpers';
import { PRIVATE_ROUTES } from './Router.privateRoutes';
import { PRIVATE_ROUTES_OLD } from './Router.privateRoutesOLD';

/** PUBLIC ROUTES */
const Login = lazy((): any => import('pages/Auth/Login'));
const SSO = lazy((): any => import('pages/Auth/SSO'));
const Register = lazy((): any => import('pages/Auth/Register'));
const Forgot = lazy((): any => import('pages/Auth/ForgetPassword'));
const Reset = lazy((): any => import('pages/Auth/ResetPassword'));
const Terms = lazy((): any => import('pages/Legal/Terms'));
const Privacy = lazy((): any => import('pages/Legal/Privacy'));
const Expired = lazy((): any => import('pages/Expired'));
const Forbidden = lazy((): any => import('pages/Forbidden'));
const SubscriberNotification = lazy((): any => import('pages/Auth/SubscriberNotification'));
const NotFound = lazy((): any => import('pages/NotFound'));

export default function Router() {
  useUpdateIsOnFrozenPage();
  useSetSentryUser();

  return (
    <>
      <FeatureFlag feature={'REACT_APP_FEATURE_FLAG_WEATHER_LAYERS'}>
        <Feature enabled={true}>
          <Suspense fallback={<Loading />}>
            <Switch>
              {PRIVATE_ROUTES.map((page) => (
                <PrivateRoute exact key={page.path} path={page.path} component={page.component} />
              ))}
              {/** BEGIN PUBLIC ROUTES */}
              <Route exact path='/register/:token' component={Register} />
              <Route exact path='/forgot' component={Forgot} />
              <Route exact path='/reset/:token' component={Reset} />
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/expired' component={Expired} />
              <Route exact path='/forbidden' component={Forbidden} />
              <Route exact path='/ssocallback' component={SSOCallback} />
              {/** `token` should be given for community subscribers; others will need a normal auth (check in component itself) **/}
              <Route exact path='/preference/notification/:token?' component={SubscriberNotification} />
              <Route exact path='/login/sso' component={SSO} />
              <Route exact path='/login' component={Login} />
              {/** END PUBLIC ROUTES */}
              <Route path='*' component={NotFound} />
            </Switch>
          </Suspense>
        </Feature>
        <Feature enabled={false}>
          <Suspense fallback={<Loading />}>
            <Switch>
              {PRIVATE_ROUTES_OLD.map((page) => (
                <PrivateRoute exact key={page.path} path={page.path} component={page.component} />
              ))}
              {/** BEGIN PUBLIC ROUTES */}
              <Route exact path='/register/:token' component={Register} />
              <Route exact path='/forgot' component={Forgot} />
              <Route exact path='/reset/:token' component={Reset} />
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/expired' component={Expired} />
              <Route exact path='/forbidden' component={Forbidden} />
              <Route exact path='/ssocallback' component={SSOCallback} />
              {/** `token` should be given for community subscribers; others will need a normal auth (check in component itself) **/}
              <Route exact path='/preference/notification/:token?' component={SubscriberNotification} />
              <Route exact path='/login/sso' component={SSO} />
              <Route exact path='/login' component={Login} />
              {/** END PUBLIC ROUTES */}
              <Route path='*' component={NotFound} />
            </Switch>
          </Suspense>
        </Feature>
      </FeatureFlag>
    </>
  );
}
