import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { API_HEADER_MFA } from 'config/constants';
import { rsaLogoutUser, rsaMfaModalOpen, rsaRedirectUrl, rsaSecuritySettingsChanged, rsaUser } from 'data';
import { Location } from 'history';
import { useLocation } from 'react-router';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { ApiMfaStatus } from 'types';

interface Props {
  redirectToLoginPage?: boolean;
  callLogoutApi?: boolean;
  securitySettingsChanged?: boolean;
}

export type UnauthorizedHandler = (_res: AxiosResponse) => void;

export default function useLogout({
  callLogoutApi = true,
  securitySettingsChanged = false,
}: Props): UnauthorizedHandler {
  const logoutUser = useRecoilValue(rsaLogoutUser);
  const setMfaModalOpen: SetterOrUpdater<boolean> = useSetRecoilState(rsaMfaModalOpen);
  const setRedirectUrl = useSetRecoilState(rsaRedirectUrl);
  const setUser = useSetRecoilState(rsaUser);
  const location: Location = useLocation();
  const setSecuritySettingsChanged: SetterOrUpdater<boolean> = useSetRecoilState(rsaSecuritySettingsChanged);

  return useCallback(
    async (response: AxiosResponse) => {
      const { status, headers } = response || {};
      if (status === 401 && headers[API_HEADER_MFA] === ApiMfaStatus.Required) {
        setMfaModalOpen(true);
        return;
      }

      /**
       * @todo - see if we can just check if localstorage PANO USER and no FROZEN USER
       * @Concern - make sure shared pages while logged in still work
       */
      if (callLogoutApi) {
        await logoutUser();
      }

      window.localStorage.clear();
      setUser(null);
      setRedirectUrl(securitySettingsChanged ? '' : location.pathname);
      setSecuritySettingsChanged(securitySettingsChanged);

      window.location.reload();
    },
    [
      callLogoutApi,
      setUser,
      setRedirectUrl,
      securitySettingsChanged,
      location.pathname,
      setSecuritySettingsChanged,
      setMfaModalOpen,
      logoutUser,
    ],
  );
}
