import React from 'react';
import Link from 'componentLibrary/Link';
import PICWorkflowFlag, { PICFeature } from 'components/PICWorkflow/PICWorkflowFlag/PICWorkflowFlag';
import { rsaIsFullAuth, rsaIsFullAuthFrozen, rsaUserEmail } from 'data';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import LogoutButton from './LogoutButton';
import PICLogoutButton from './PICLogoutButton';
import styles from './UserMenuItems.styles';

const legalPages: [string, string] = ['/terms', '/privacy'];

const UserMenuItems = ({ setProfileMenuAnchorEl }: { setProfileMenuAnchorEl: React.Dispatch<Element> }) => {
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const isFullAuthFrozen = useRecoilValue(rsaIsFullAuthFrozen);
  const userEmail = useRecoilValue(rsaUserEmail);
  const location: Location = useLocation();

  const isLegalPage = !legalPages.includes(location.pathname);

  return (
    <>
      {(isFullAuth || isFullAuthFrozen) && (
        <>
          <Box sx={styles['menu-header']} data-cy='menu-header'>
            Account
            <Typography sx={styles.email} variant='body2'>
              {userEmail}
            </Typography>
          </Box>
          <MenuItem key='menu-settings' data-cy='menu-settings'>
            <Link to='/account/settings' sx={styles['profile-popver-item']}>
              Account Settings
            </Link>
          </MenuItem>
        </>
      )}
      <PICWorkflowFlag>
        <PICFeature enabled>
          <PICLogoutButton setProfileMenuAnchorEl={setProfileMenuAnchorEl} />
        </PICFeature>
        <PICFeature enabled={false}>
          <LogoutButton setProfileMenuAnchorEl={setProfileMenuAnchorEl} />
        </PICFeature>
      </PICWorkflowFlag>
      {isLegalPage && (
        <>
          <Divider key='menu-divider' light sx={styles.divider} />
          <MenuItem key='menu-tos' data-cy='menu-tos'>
            <Link to='/terms' sx={styles['profile-popver-item']} target='_blank'>
              Terms of Service
            </Link>
          </MenuItem>
          <MenuItem key='menu-privacy' data-cy='menu-privacy'>
            <Link to='/privacy' sx={styles['profile-popver-item']} target='_blank'>
              Privacy Policy
            </Link>
          </MenuItem>
        </>
      )}
    </>
  );
};

export default UserMenuItems;
