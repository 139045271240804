import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { FeatureFlagEvaluation, FeatureFlagValue } from 'types';

/**
 * Client for the remote feature flag evaluator.
 */
export default class FeatureFlagsApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    this.api = await authApi();
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  private async apiGetFeatureFlagEvaluation<T extends FeatureFlagValue>(
    flagKey: string,
    defaultValue: T,
    flagType: string,
  ): Promise<FeatureFlagEvaluation<T>> {
    try {
      const path = `/feature-flag/${flagKey}/evaluation`;
      const queryString = new URLSearchParams();
      queryString.append('type', flagType);
      if (defaultValue) {
        queryString.append('default', defaultValue.toString());
      }
      const url = `${path}?${queryString}`;
      const resp = await this.api.get(url);
      if (resp.status === 200) {
        return resp.data;
      }
      return {
        value: defaultValue,
        errorCode: 'GENERAL',
        errorMessage: `HTTP ${resp.status}: ${resp.statusText}`,
        reason: 'ERROR',
      };
    } catch (e) {
      return {
        value: defaultValue,
        errorCode: 'GENERAL',
        errorMessage: e.toString(),
        reason: 'ERROR',
      };
    }
  }

  /**
   * Evaluate a boolean feature flag for the current user.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the flag evalaution.
   */
  async apiGetBooleanFeatureFlagEvaluation(
    flagKey: string,
    defaultValue: boolean = false,
  ): Promise<FeatureFlagEvaluation<boolean>> {
    return await this.apiGetFeatureFlagEvaluation(flagKey, defaultValue, 'BOOLEAN');
  }

  /**
   * Evaluate a string feature flag for the current user.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the flag evalaution.
   */
  async apiGetStringFeatureFlagEvaluation(
    flagKey: string,
    defaultValue: string = null,
  ): Promise<FeatureFlagEvaluation<string>> {
    return await this.apiGetFeatureFlagEvaluation(flagKey, defaultValue, 'STRING');
  }

  /**
   * Evaluate a number feature flag for the current user.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the flag evalaution.
   */
  async apiGetNumberFeatureFlagEvaluation(
    flagKey: string,
    defaultValue: number = 0,
  ): Promise<FeatureFlagEvaluation<number>> {
    return await this.apiGetFeatureFlagEvaluation(flagKey, defaultValue, 'DOUBLE');
  }
}
