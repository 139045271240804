import { USER_MAP_INFO_FIELD } from 'config/base';
import { localStorageEffect } from 'data/effect';
import { MapApis } from 'data/proxyApi';
import { LngLatBounds } from 'react-map-gl';
import { atom, RecoilState, selector, selectorFamily } from 'recoil';
import { MapSearchResultFeature, MapViewport } from 'types';
import { UnitSystem } from 'weatherlayers-gl';

import { rsaAccountUnit } from '../authStore';

/**
 * Local map info: location, zoom level, etc.
 */
export const rsmLocalMapInfo: RecoilState<MapViewport> = atom<MapViewport>({
  key: 'rsmLocalMapInfo',
  default: null,
  effects: [localStorageEffect(USER_MAP_INFO_FIELD, null)],
});

/**
 * Searches for locations given a query string with mapbox's API
 */
export const rsmLocationSearchResults = selectorFamily({
  key: 'rsmLocationSearchResults',
  get: (query: string) => async () => await MapApis.apiGetSearchLocation(query),
});

export const rsmSelectedLocation: RecoilState<MapSearchResultFeature> = atom<MapSearchResultFeature>({
  key: 'rsmSelectedLocation',
  default: null,
});

/**
 * The viewport of the main map in a page
 * - e.g. Incident Map, Station Map, Main Map
 * - NOT FOR Widget Map
 */
export const rsmIncidentMapBounds = atom<LngLatBounds>({
  key: 'rsmIncidentMapBounds',
  default: null,
});

/**
 * The viewport of the widget map
 */
export const rsmWidgetMapBounds = atom<LngLatBounds>({
  key: 'rsmWidgetMapBounds',
  default: null,
});

export const rsmMapUnits = selector<UnitSystem>({
  key: 'rsmMapUnits',
  get: ({ get }) => {
    const accountUnit = get(rsaAccountUnit);
    return accountUnit === 'si' ? UnitSystem.METRIC : UnitSystem.IMPERIAL;
  },
});
