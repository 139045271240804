import { UsState } from 'types';

const US_STATES: UsState[] = [
  {
    name: 'Alabama',
    abbreviation: 'al',
  },
  {
    name: 'Alaska',
    abbreviation: 'ak',
  },
  {
    name: 'American Samoa',
    abbreviation: 'as',
  },
  {
    name: 'Arizona',
    abbreviation: 'az',
  },
  {
    name: 'Arkansas',
    abbreviation: 'ar',
  },
  {
    name: 'California',
    abbreviation: 'ca',
  },
  {
    name: 'Colorado',
    abbreviation: 'co',
  },
  {
    name: 'Connecticut',
    abbreviation: 'ct',
  },
  {
    name: 'Delaware',
    abbreviation: 'de',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'dc',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'fm',
  },
  {
    name: 'Florida',
    abbreviation: 'fl',
  },
  {
    name: 'Georgia',
    abbreviation: 'ga',
  },
  {
    name: 'Guam',
    abbreviation: 'gu',
  },
  {
    name: 'Hawaii',
    abbreviation: 'hi',
  },
  {
    name: 'Idaho',
    abbreviation: 'id',
  },
  {
    name: 'Illinois',
    abbreviation: 'il',
  },
  {
    name: 'Indiana',
    abbreviation: 'in',
  },
  {
    name: 'Iowa',
    abbreviation: 'ia',
  },
  {
    name: 'Kansas',
    abbreviation: 'ks',
  },
  {
    name: 'Kentucky',
    abbreviation: 'ky',
  },
  {
    name: 'Louisiana',
    abbreviation: 'la',
  },
  {
    name: 'Maine',
    abbreviation: 'me',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'mh',
  },
  {
    name: 'Maryland',
    abbreviation: 'md',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'ma',
  },
  {
    name: 'Michigan',
    abbreviation: 'mi',
  },
  {
    name: 'Minnesota',
    abbreviation: 'mn',
  },
  {
    name: 'Mississippi',
    abbreviation: 'ms',
  },
  {
    name: 'Missouri',
    abbreviation: 'mo',
  },
  {
    name: 'Montana',
    abbreviation: 'mt',
  },
  {
    name: 'Nebraska',
    abbreviation: 'ne',
  },
  {
    name: 'Nevada',
    abbreviation: 'nv',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'nh',
  },
  {
    name: 'New Jersey',
    abbreviation: 'nj',
  },
  {
    name: 'New Mexico',
    abbreviation: 'nm',
  },
  {
    name: 'New York',
    abbreviation: 'ny',
  },
  {
    name: 'North Carolina',
    abbreviation: 'nc',
  },
  {
    name: 'North Dakota',
    abbreviation: 'nd',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'mp',
  },
  {
    name: 'Ohio',
    abbreviation: 'oh',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'ok',
  },
  {
    name: 'Oregon',
    abbreviation: 'or',
  },
  {
    name: 'Palau',
    abbreviation: 'pw',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'pa',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'pr',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'ri',
  },
  {
    name: 'South Carolina',
    abbreviation: 'sc',
  },
  {
    name: 'South Dakota',
    abbreviation: 'sd',
  },
  {
    name: 'Tennessee',
    abbreviation: 'tn',
  },
  {
    name: 'Texas',
    abbreviation: 'tx',
  },
  {
    name: 'Utah',
    abbreviation: 'ut',
  },
  {
    name: 'Vermont',
    abbreviation: 'vt',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'vi',
  },
  {
    name: 'Virginia',
    abbreviation: 'va',
  },
  {
    name: 'Washington',
    abbreviation: 'wa',
  },
  {
    name: 'West Virginia',
    abbreviation: 'wv',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'wi',
  },
  {
    name: 'Wyoming',
    abbreviation: 'wy',
  },
];

const AU_STATES: UsState[] = [
  {
    name: 'Australian Capital Territory',
    abbreviation: 'act',
  },
  {
    name: 'New South Wales',
    abbreviation: 'nsw',
  },
  {
    name: 'Northern Territory',
    abbreviation: 'nt',
  },
  {
    name: 'Queensland',
    abbreviation: 'qld',
  },
  {
    name: 'South Australia',
    abbreviation: 'sa',
  },
  {
    name: 'Tasmania',
    abbreviation: 'tas',
  },
  {
    name: 'Victoria',
    abbreviation: 'vic',
  },
  {
    name: 'Western Australia',
    abbreviation: 'wa',
  },
];

const ALL_STATES: UsState[] = [...US_STATES, ...AU_STATES];

const STATE_NAME_ABBR_MAP: Record<string, string> = {};

ALL_STATES.forEach((s) => (STATE_NAME_ABBR_MAP[s.abbreviation] = s.name));

export const getStateNameFromAbbr = (abbr: string): string => STATE_NAME_ABBR_MAP[abbr] || '';
