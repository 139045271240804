import { countryCodes } from 'config/base';
import { MIN_INCIDENT_DATE } from 'config/constants';
import _startsWith from 'lodash/startsWith';
import { IncidentLabel } from 'types';
import { isLabelInactive } from 'utils/incident';
import { isValidDate } from 'utils/moment';
import * as Yup from 'yup';

import 'yup-phone-lite';

export const invalidSpaceOnly: string = 'Input with only spaces is not allowed';
export const required: string = 'Please provide a value for this field';
const invalidEmail: string = 'Email address is in an incorrect format';
export const invalidPassword: string =
  'Passwords should be at least 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol from #?!@$%^&*- .';
const tosNotAccepted: string = 'Please check the box to agree to the Terms of Service and Privacy Policy';
export const invalidPhoneNumber: string = 'Invalid phone number';
export const phoneNotVerifiedError: string = 'Please verify your phone number';
export const invalidUsPhoneNumber: string = invalidPhoneNumber;
const invalidAuPhoneNumber: string = invalidUsPhoneNumber;
const invalidFutureDate = (v: string): string => `${v} cannot be in the future`;
const startTimeRangeError: string = 'Incident start cannot be after incident end';
const endTimeRangeError: string = 'Incident end cannot be before incident start';
export const invalidDateTimeFormat: string = 'Please enter a valid data/time in format: "yyyy/MM/dd HH:mm"';
const invalidDateRange: string = 'The date provided is out of the accepted range';

export const LEADING_TRAILING_SPACE_REGEXP: RegExp = /^(.*)?\S+(.*)?$/gm;
const HAS_SOME_NON_WHITESPACE_CHAR_REGEXP: RegExp = /^(.*)\S/m;
export const PASSWORD_REGEXP: RegExp =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/g;

const usPhoneSchema: Yup.StringSchema = Yup.string().phone('US', invalidUsPhoneNumber).required(invalidUsPhoneNumber);
const auPhoneSchema: Yup.StringSchema = Yup.string().phone('AU', invalidAuPhoneNumber).required(invalidAuPhoneNumber);

export const noOnlySpaceSchema: Yup.StringSchema = Yup.string().matches(
  LEADING_TRAILING_SPACE_REGEXP,
  invalidSpaceOnly,
);
const noOnlySpaceSchemaMultiLine: Yup.StringSchema = Yup.string().matches(
  HAS_SOME_NON_WHITESPACE_CHAR_REGEXP,
  invalidSpaceOnly,
);
const requiredSchema: Yup.StringSchema = Yup.string()
  .matches(LEADING_TRAILING_SPACE_REGEXP, invalidSpaceOnly)
  .required(required);

const getCountrySchema = (
  phonesVerified: Record<string, boolean>,
  input: string,
  errorMessage?: string,
): Yup.StringSchema => {
  const schema: Yup.StringSchema = !input || _startsWith(String(input), '1') ? usPhoneSchema : auPhoneSchema;

  return schema.test('verify', errorMessage || phoneNotVerifiedError, (v) => phonesVerified[v]);
};

export const accountSettingsSchema =
  (phonesVerified: Record<string, boolean>, isUserSetUpForMFA: boolean) => (): object =>
    Yup.object({
      phone: Yup.string()
        .when(['isUserSSO', 'mfa'], {
          is: (isUserSSO: boolean, mfa: boolean) => isUserSSO && mfa && isUserSetUpForMFA,
          then: Yup.lazy((val) =>
            getCountrySchema(phonesVerified, val, 'A phone number is required by your organization'),
          ),
        })
        .when(['mfa', 'notifyPhone', 'isUserSSO'], {
          is: (mfa: boolean, notifyPhone: boolean, isUserSSO: boolean) => {
            if (isUserSSO && mfa && !isUserSetUpForMFA && !notifyPhone) {
              return false;
            }
            return mfa || notifyPhone;
          },
          then: Yup.lazy((val) => getCountrySchema(phonesVerified, val)),
          otherwise: Yup.lazy((val) =>
            countryCodes.includes(val) ? Yup.string().nullable() : getCountrySchema(phonesVerified, val),
          ),
        }),
    });

export const idPDiscoveryValidationSchema = () =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
  });

export const loginValidationSchema = (): object =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
    password: Yup.string().defined(required),
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const forgotValidationSchema = (): object =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
  });

export const phoneVerificationCodeSchema = (): object =>
  Yup.object({
    code: requiredSchema,
  });

export const inviteValidationSchema = () =>
  Yup.object({
    email: Yup.string().email(invalidEmail).defined(required),
    selectedOrganization: requiredSchema,
  });

// @todo Hack org name for Portland
export const orgIsPge = (orgName: string): boolean => ['OR Portland General Electric'].includes(orgName);

export const resetPasswordValidationSchema = (): object =>
  Yup.object({
    password: Yup.string().defined(required).matches(PASSWORD_REGEXP, invalidPassword),
  });

export const registerSubscriberValidationSchema = (): object =>
  Yup.object({
    firstName: requiredSchema,
    lastName: requiredSchema,
    company: requiredSchema,
    title: noOnlySpaceSchema,
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

export const registerValidationSchema = (): object =>
  Yup.object({
    firstName: requiredSchema,
    lastName: requiredSchema,
    company: requiredSchema,
    title: noOnlySpaceSchema,
    password: Yup.string().defined(required).matches(PASSWORD_REGEXP, invalidPassword),
    acceptTos: Yup.boolean().oneOf([true], tosNotAccepted),
  });

/**
 *
 * @param isSubscriber - Wether the user is just subscribing for alerts
 * @returns The registration validations chema
 */
export const getRegisterValidationSchema = (isSubscriber: boolean): object => {
  if (isSubscriber) {
    return registerSubscriberValidationSchema;
  }

  return registerValidationSchema;
};

export const updateIncidentValidationSchema = (): object =>
  Yup.object({
    label: requiredSchema,
    name: noOnlySpaceSchema,
    info: noOnlySpaceSchemaMultiLine,
    startTime: Yup.date()
      .required(required)
      .min(MIN_INCIDENT_DATE, invalidDateRange)
      .when(['label', 'endTime'], {
        is: (label: IncidentLabel, endTime: string | number | Date) => isLabelInactive(label) && isValidDate(endTime),
        then: Yup.date().max(Yup.ref('endTime'), startTimeRangeError),
      })
      .typeError(invalidDateTimeFormat)
      .max(new Date(), invalidFutureDate('Incident start')),
    endTime: Yup.date()
      .when('label', {
        is: (label: IncidentLabel) => isLabelInactive(label),
        then: Yup.date().required(required),
      })
      .min(Yup.ref('startTime'), endTimeRangeError)
      .nullable()
      .typeError(invalidDateTimeFormat)
      .min(MIN_INCIDENT_DATE, invalidDateRange)
      .max(new Date(), invalidFutureDate('Incident end')),
  });

/** Both US and UA phone numbers have 11 digits. */
export const MAGIC_PHONE_NUMBER_LENGTH: number = 11;
/**
 * Simply check phone number length
 * @param len
 */
export const phoneLengthIsValid = (len: number): boolean => MAGIC_PHONE_NUMBER_LENGTH === len;
