import axios, { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { DEFAULT_PAN_TILT_ZOOM } from 'config/constants';
import { ApiResponse, OzEndedApiResponseData, OzEndedReason, Ptz, Station } from 'types';

type UpdatePtzParams = Ptz & {
  stationId: number;
};

interface RequestOzParams {
  /** value of the `st=` query parameter that comes from an alert url */
  sharedById?: string;
  /** Id of the alerted incident */
  incidentId: number;
  /** Station camera for which a user wants to request OZ */
  stationId: number;
  /** The name of the anonymous user */
  userName?: string;
  /** The Title of the anonymous user */
  userTitle?: string;
  /** The email of the anonymous user */
  userEmail?: string;
}

export default class OzApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    const api: AxiosInstance = await authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * Updates the OZ session to point to new Pan Tilt Zoom settings
   * @returns status data and headers for the PUT request
   */
  async apiPutUpdatePtz({ stationId, pan, tilt, zoom }: UpdatePtzParams): Promise<ApiResponse<Station>> {
    try {
      const { status, data, headers } = await this.api({
        method: 'put',
        url: `/ec/${stationId}/oz`,
        data: {
          pan: Number(pan.toFixed(1)),
          tilt: Number(tilt.toFixed(1)),
          zoom,
        },
        headers: {
          'If-Match': 'manual',
        },
        shouldSkipErrorHandling: true,
      });
      return { status, data, headers };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const { status, data, headers } = e.response;
        return { status, data, headers };
      }
      throw new Error(`Failed to update PTZ of station ${stationId}`);
    }
  }

  /**
   * Starts the OZ session
   * @param stationId - the id of the station to start an OZ session
   * @param pan - The bearing to start the OZ session. By default 0
   * @returns the status, data, and headers of the PUT request
   */
  async apiPutStartStationOz(stationId: number, pan: number): Promise<ApiResponse<Station>> {
    try {
      const { status, data, headers } = await this.api({
        method: 'put',
        url: `/ec/${stationId}/oz`,
        data: {
          ...DEFAULT_PAN_TILT_ZOOM,
          pan,
        },
        headers: {
          'If-Match': 'pano',
        },
        shouldSkipErrorHandling: true,
      });

      return { status, data, headers };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const { status, data, headers } = e.response;
        return { status, data, headers };
      }
      throw new Error(`Failed to start OZ of station ${stationId}`);
    }
  }

  /**
   * Ends the OZ session for this station
   * @param stationId - The ID
   * @param reason - The reason the oz session is being ended
   * @returns Ended Response data or null
   */
  async apiDeleteEndStationOz(stationId: number, reason: OzEndedReason): Promise<OzEndedApiResponseData> {
    try {
      const { data, status } = await this.api.delete(`/ec/${stationId}/oz?reason=${reason}`, {
        shouldSkipErrorHandling: true,
      });

      return status >= 200 && status < 300 ? data : null;
    } catch (e) {
      throw new Error(`Failed to end OZ of station ${stationId}`);
    }
  }

  /**
   * Requests an OZ session to be started for a particular EC
   * @param stationId - the id of the station for which to request an OZ session
   * @param incidentId - The id of the incident this is associated with
   * @param sharedById - optional -  The account ID of the user who initially received the shared link
   * @returns the status, data, and headers of the PUT request
   */
  async apiRequestOzSession({
    stationId,
    incidentId,
    sharedById,
    userName,
    userEmail,
    userTitle,
  }: RequestOzParams): Promise<ApiResponse<Station>> {
    try {
      const { status, data, headers } = await this.api({
        method: 'POST',
        url: `/ec/${stationId}/oz/request`,
        data: {
          incidentId,
          ...(sharedById && { sharedById: sharedById.trim() }),
          ...(userName && { userName: userName.trim() }),
          ...(userEmail && { userEmail: userEmail.trim() }),
          ...(userTitle && { userTitle: userTitle.trim() }),
        },
      });

      return { status, data, headers };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const { status, data, headers } = e.response;
        return { status, data, headers };
      }
      throw new Error(`Failed to start OZ of station ${stationId}`);
    }
  }
}
