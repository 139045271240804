import React from 'react';

interface Props {
  /** The content to render wrapped in Feature componetnts */
  children: React.ReactElement<FeatureProps>[] | React.ReactElement<FeatureProps>;
  /** The name of the feature in the env file. Should be like REACT_APP_FEATURE_FLAG_<NAME> */
  feature: string;
}

/**
 * This is a component for handling rendering flagged Features
 * - Children need to be wrapped in Feature components, and marked as enabled or disabled
 */
export default function FeatureFlag({ feature, children }: Props) {
  if (process.env[feature] !== 'true' && process.env[feature] !== 'false') {
    return null;
  }

  const isFeatureEnabled: boolean = process.env[feature] === 'true';
  const filteredChildren: React.ReactNode[] = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    } else if ((child as React.ReactElement<FeatureProps>).props.enabled === isFeatureEnabled) {
      return child;
    }

    return null;
  }).filter(Boolean);

  return <>{filteredChildren}</>;
}

interface FeatureProps {
  /** The content to render */
  children: React.ReactElement<FeatureProps>[] | React.ReactElement<FeatureProps>;
  /**
   * Described wether to show the children if enabled or disabled
   * - If this is true. Show the children when the feature flag is true
   * - If this is false. Show the children when the feature flag is false
   */
  enabled: boolean;
}

/**
 * This is a wrapper component for the children of Feature Flags
 * - This allows us to render fallback content for Features in enabled/disabled state
 * - If this has enabled=true, this will render when the flag is enabled
 * - If this has enabled=false, this will render when the falg is disabled
 */
function Feature({ children }: FeatureProps): React.ReactElement {
  return <>{children}</>;
}

export { Feature, FeatureFlag };
