/* eslint no-unused-vars: "off" */

/**
 * no-unused-vars is ignored for this file
 *
 * @ref https://eslint.org/docs/latest/rules/no-unused-vars
 */

export enum PICTaskDisposition {
  needsReview = 'needs_review',
  duplicate = 'duplicate',
  noSmoke = 'no_smoke',
  nonAlertableSmoke = 'non_alertable_smoke',
}

export enum InferenceModel {
  day = 'day',
  night = 'night',
}

export enum AlertLevel {
  InProgress = 'in-progress',
  Error = 'error',
  Warning = 'warning',
}

export enum RecoilState {
  Loading = 'loading',
  HasValue = 'hasValue',
  HasError = 'hasError',
}

export enum StationOwnerType {
  Pano = 'pano',
  ThirdParty = 'third-party',
  Mixed = 'mixed',
}

export enum IncidentNotificationType {
  CreateIncident = 'create_incident',
  UpdateIncident = 'update_incident',
}

export enum Role {
  /**
   * Access to Pano 360
   */
  User = 'user',
  /**
   * Access to alerts
   */
  Subscriber = 'subscriber',
}

export enum Permission {
  OzController = 'oz_controller',
  UpdateIncident = 'incident_create_update',
}

export enum CoordinateSystem {
  GDA2020 = 'GDA2020',
  WGS84 = 'WGS84',
}

export enum MapAssetDataSet {
  PowerLine = 'powerline',
  PgeOverheadMainlines = 'pge-overhead-mainline',
  PgeOverheadTaplines = 'pge-overhead-tapline',
  PgeUndergroundTaplines = 'pge-underground-tapline',
  PgeUndergroundMainlines = 'pge-underground-mainline',
  PgeHydroPlant = 'pge-hydroplants',
  PgeLocation = 'pge-locations',
  PgeSubstation = 'pge-substation',
  HvpPlantation = 'hvp-plantation',
}

export enum ByUser {
  Me = 'Me',
  Others = 'Others',
}

export enum OzStatus {
  Started = 'started',
  Expired = 'expired',
  NoImages = 'no-images',
  Ended = 'ended',
  EndedByOthers = 'ended-by-others',
  Void = 'void',
}

export enum MapVariant {
  MainMap = 'main-map',
  Widget = 'widget',
}

export enum MfaMethod {
  Sms = 'sms',
}

export enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}

export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  AlreadyReported = 208,
  Redirect = 300,
  PreconditionFailed = 412,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotAcceptable = 406,
  Conflict = 409,
  Gone = 410,
  UnprocessableEntity = 422,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export enum Severity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum ApiMfaStatus {
  Required = 'required',
  Invalid = 'invalid',
  Expired = 'expired',
}

export enum MfaModalPurpose {
  Login = 'login',
  VerifyPhone = 'verify-phone',
}

export enum PICWorkflowState {
  inactive = 'inactive',
  searching = 'searching',
  active = 'active',
}
