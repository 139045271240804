import { ACTIVE_TASK, HAS_USER_BEEN_REDIRECTED_TO_TASK, IS_TASK_ACKNOWLEDGED } from 'config/base';
import { rspFullScreenStationId } from 'data';
import { localStorageEffect, localStorageEffectLiveSync } from 'data/effect';
import { atom, selector } from 'recoil';
import { Incident, InferenceModel, PICTaskDisposition, Task } from 'types';
import { stringifyLonLat } from 'utils/map';
import { getTimeZoneAbbreviation } from 'utils/moment';

import { rsiIncidentMark } from '../incidentStore';
import { rssQueryStationById } from '../stationStore';

/**
 * The current task for the user
 * - This is live synced across tabs.
 */
export const rstTask = atom<Task>({
  key: 'rstTask',
  default: null,
  effects: [localStorageEffectLiveSync<boolean>(ACTIVE_TASK, null)],
});

/**
 * Whether the current task has been acknowledged
 * - Acknowledged - The user has focused a tab with the task in it
 * - This is live synced across tabs
 */
export const rstIsTaskAcknowledged = atom<boolean>({
  key: 'rstIsTaskAcknowledged',
  default: null,
  effects: [localStorageEffectLiveSync<boolean>(IS_TASK_ACKNOWLEDGED, null)],
});

/**
 * The user chosen disposition for the current task
 */
export const rstTaskDisposition = atom<PICTaskDisposition>({
  key: 'rstTaskDisposition',
  default: null,
});

/**
 * The id of the duplicate incident to associate with the current task
 */
export const rstDuplicateIncidentId = atom<Incident['id']>({
  key: 'rstDuplicateIncidentId',
  default: null,
});

/**
 * Whether the user has been redirected to the full screen pano for the active task
 */
export const rstHasBeenRedirected = atom({
  key: 'rstHasBeenRedirected',
  default: false,
  effects: [localStorageEffect(HAS_USER_BEEN_REDIRECTED_TO_TASK, null)],
});

/**
 * Whether the task completion request is in progress
 */
export const rstIsTaskCompletionInProgress = atom({
  key: 'rstIsTaskCompletionInProgress',
  default: false,
});

/**
 * Whether a user is viewing the full screen pano for the EC related to the current task
 */
export const rstIsFullScreenPanoForTask = selector({
  key: 'rstIsFullScreenPanoForTask',
  get: ({ get }) => {
    const fullScreenStationId = get(rspFullScreenStationId);
    const task = get(rstTask);

    return task?.ecId === fullScreenStationId;
  },
});

/**
 * Whether the user's active task can be submitted
 */
export const rstIsTaskCompletable = selector({
  key: 'rstIsTaskCompletable',
  get: ({ get }) => {
    const disposition = get(rstTaskDisposition);
    const newFireMark = get(rsiIncidentMark);
    const duplicateIncidentId = get(rstDuplicateIncidentId);
    const fullScreenStationId = get(rspFullScreenStationId);
    const task = get(rstTask);

    if (task?.ecId !== fullScreenStationId) {
      return false;
    } else if (disposition === PICTaskDisposition.needsReview && newFireMark) {
      return true;
    } else if (disposition === PICTaskDisposition.noSmoke || disposition === PICTaskDisposition.nonAlertableSmoke) {
      return true;
    } else if (disposition === PICTaskDisposition.duplicate && duplicateIncidentId) {
      return true;
    }

    return false;
  },
});

/**
 * The Id of the task
 */
export const rstTaskId = selector({
  key: 'rstTaskId',
  get: ({ get }) => {
    const task = get(rstTask);

    return task?.taskId;
  },
});

/**
 * The Source of the task
 */
export const rstTaskSource = selector({
  key: 'rstTaskSource',
  get: ({ get }) => {
    const task = get(rstTask);

    return task?.source;
  },
});

/**
 * The Image of the task
 */
export const rstTaskImageUrl = selector({
  key: 'rstTaskImageUrl',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'camera') {
      return undefined;
    }

    return task?.imageUrl;
  },
});

/**
 * Which model was used for the Camera detection
 */
export const rstInferenceModel = selector({
  key: 'rstInferenceModel',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'camera') {
      return undefined;
    }

    return task?.inferenceModelType;
  },
});

/**
 * Name of the inference model for the task
 */
export const rstInferenceModelName = selector({
  key: 'rstInferenceModelName',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'camera') {
      return undefined;
    }

    return task?.inferenceModelName;
  },
});

/**
 * Version of the inference model for the task
 */
export const rstInferenceModelVersion = selector({
  key: 'rstInferenceModelVersion',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'camera') {
      return undefined;
    }

    return task?.inferenceModelVersion;
  },
});

/**
 * The confidence level of a detection
 */
export const rstTaskConfidenceDisplay = selector({
  key: 'rstTaskConfidenceDisplay',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source === 'known_fire') {
      return undefined;
    }

    return `${Math.round(task?.score * 100).toString()}%`;
  },
});
/**
 * The stationID associated with the task
 */
export const rstTaskStationId = selector({
  key: 'rstTaskStationId',
  get: ({ get }) => {
    const task = get(rstTask);

    return task?.ecId;
  },
});

/**
 * The url to redirect a user to
 */
export const rstTaskRedirectUrl = selector({
  key: 'rstTaskRedirectUrl',
  get: ({ get }) => {
    const task = get(rstTask);
    const isUserAbleToAccessStation = get(rssQueryStationById(task?.ecId));

    if (!task || !isUserAbleToAccessStation) {
      return undefined;
    }

    return `/station/${task?.ecId}?full=1&bearing=${Math.round(task?.bearing)}`;
  },
});

export const rstIsFollowUpTask = selector({
  key: 'rstIsFollowUpTask',
  get: ({ get }) => {
    const task = get(rstTask);

    if ((task?.source === 'known_fire' || task?.source === 'satellite') && task.assignableTime) {
      return true;
    }

    return false;
  },
});

/**
 * Gets the title for the task
 */
export const rstTaskTitle = selector({
  key: 'rstTaskTitle',
  get: ({ get }) => {
    const task = get(rstTask);
    const isFollowUpTask = get(rstIsFollowUpTask);

    if (task?.source === 'known_fire') {
      return `Known Fire Feed ${isFollowUpTask ? `(Follow-up)` : ``}`.trim();
    } else if (task?.source === 'satellite') {
      return `Satellite AI Prediction ${isFollowUpTask ? `(Follow-up)` : ``}`.trim();
    } else if (task?.source === 'camera' && task.inferenceModelType === InferenceModel.night) {
      return 'Nighttime AI Prediction';
    }

    return 'Daytime AI Prediction';
  },
});

/**
 * The display content for the Station line of a Task
 */
export const rstTaskStationDisplay = selector({
  key: 'rstTaskSourceDisplay',
  get: ({ get }) => {
    const task = get(rstTask);

    if (!task) {
      return undefined;
    }

    return `${task.ecName}, EC ${task.ecId}, DOI ${task.doi}, Bearing ${task.bearing.toFixed(2)}°`;
  },
});

/**
 * The display content for the Date line of a Task
 * - We are currently hardcoding the timezone to PT
 */
export const rstTaskDateDisplay = selector({
  key: 'rstTaskDateDisplay',
  get: ({ get }) => {
    const task = get(rstTask);

    if (!task) {
      return undefined;
    }

    const date = new Date(task.captureTime * 1000);
    const timeZoneAbbreviation = getTimeZoneAbbreviation({ date, timezone: 'America/Los_Angeles' });

    return `${date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })} ${timeZoneAbbreviation}`;
  },
});

/**
 * The display label for the Date line of a Task
 */
export const rstTaskDateDisplayLabel = selector({
  key: 'rstTaskDateDisplayLabel',
  get: ({ get }) => {
    const task = get(rstTask);

    if (!task) {
      return undefined;
    } else if (task.source === 'known_fire') {
      return 'Report of Fire created on';
    }

    return 'Detection Capture Time';
  },
});

/**
 * The Name of the Satellite for a task
 */
export const rstTaskSatelliteName = selector({
  key: 'rstTaskSatelliteName',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return task.satelliteName;
  },
});

/**
 * The distance and unit associated with a task
 */
export const rstTaskDistanceDisplay = selector({
  key: 'rstTaskDistanceDisplay',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite' && task?.source !== 'known_fire') {
      return undefined;
    }

    return `${task.distance.toFixed(2)} km`;
  },
});

/**
 * The stringified coordinates associated with a task
 */
export const rstTaskCoordinates = selector({
  key: 'rstTaskCoordinates',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite' && task?.source !== 'known_fire') {
      return undefined;
    }

    return stringifyLonLat(task.coordinates);
  },
});

/**
 * The temperature and unit associated with a Satellite detection task
 */
export const rstTaskTemperatureDisplay = selector({
  key: 'rstTaskTemperatureDisplay',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite' || !task?.temperature) {
      return undefined;
    }

    return `${task.temperature}°K`;
  },
});

/**
 * The tile Index associated with a Satellite Detection task
 */
export const rstTileIndex = selector({
  key: 'rstTileIndex',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return task.tileIndex;
  },
});

/**
 * The Stringified Pixel Location associated with a Satellite Detection task
 */
export const rstPixelLoc = selector({
  key: 'rstPixelLoc',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return `${task.pixelLoc[0]}, ${task.pixelLoc[1]}`;
  },
});

/**
 * The Area (in square meteres) associated with a Satellite Detection task
 */
export const rstArea = selector({
  key: 'rstArea',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return `${task.area} m²`;
  },
});

/**
 * The Model Version with a Satellite Detection task
 */
export const rstSatelliteModelVersion = selector({
  key: 'rstSatelliteModelVersion',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return task.satelliteModelVersion;
  },
});

/**
 * The Model Name with a Satellite Detection task
 */
export const rstSatelliteModelName = selector({
  key: 'rstSatelliteModelName',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return task.satelliteModelName;
  },
});

/**
 * The Product Id with a Satellite Detection task
 */
export const rstProductId = selector({
  key: 'rstProductId',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'satellite') {
      return undefined;
    }

    return task.productId;
  },
});

/**
 * The Known Fire Name associated with a known fire task
 */
export const rstTaskKnownFireName = selector({
  key: 'rstTaskKnownFireName',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'known_fire') {
      return undefined;
    }

    return task.knownFireName;
  },
});

/**
 * The Known Fire Source associated with a task
 */
export const rstTaskKnownFireSource = selector({
  key: 'rstTaskKnownFireSource',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'known_fire') {
      return undefined;
    }

    return task.knownFireSource;
  },
});

/**
 * The Known Fire Id associated with a task
 */
export const rstTaskKnownFireId = selector({
  key: 'rstTaskKnownFireId',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'known_fire') {
      return undefined;
    }

    return task.knownFireId;
  },
});

/**
 * The Bounding Box to draw on the image for the camera detection
 */
export const rstTaskBoundingBox = selector({
  key: 'rstTaskBoundingBox',
  get: ({ get }) => {
    const task = get(rstTask);

    if (task?.source !== 'camera') {
      return undefined;
    }

    return task.bbox;
  },
});

/**
 * The expiration time of the task in ms
 */
export const rstTaskExpirationTimeMs = selector({
  key: 'rstTaskExpirationTimeMs',
  get: ({ get }) => {
    const task = get(rstTask);

    if (!task?.expirationTime) {
      return undefined;
    }

    return task?.expirationTime * 1000;
  },
});
