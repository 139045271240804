import { FROZEN_USER_FIELD, REDIRECT_URL, USER_FIELD } from 'config/base';
import { rsiFeaturedIncident, rsiSelectedStationId, rssQueryStationById } from 'data';
import { localStorageEffect } from 'data/effect';
import _head from 'lodash/head';
import { atom, RecoilState, selector } from 'recoil';
import { CoordinateSystem, OrgInfo, PanoUser, UnitSystemShorthand } from 'types';
import { getUnitsByCountry } from 'utils/getUnitsByCountry';

/**
 * The current user with bearer token
 * @todo https://panoai.atlassian.net/wiki/spaces/Product/pages/1273495553/Auth+-+Bearer+Token+Cross-Tabs
 */
export const rsaUser: RecoilState<PanoUser> = atom<PanoUser>({
  key: 'rsaUser',
  default: null,
  effects: [localStorageEffect<PanoUser>(USER_FIELD, null)],
});

/**
 * The current user with bearer token
 */
export const rsaFrozenUser = atom<PanoUser>({
  key: 'rsaFrozenUser',
  default: null,
  effects: [localStorageEffect<PanoUser>(FROZEN_USER_FIELD, null)],
});

/**
 * The user uuid associated with shareable link
 * This comes from the `st` query param
 * - this is from when a community user gets an email link
 */
export const rsaSharedLinkAccountId = atom<string>({
  key: 'rsaSharedLinkAccountId',
  default: null,
});

export const rsaUserEmail = selector<string>({
  key: 'rsaUserEmail',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    const frozenUser: PanoUser = get(rsaFrozenUser);
    return user?.email || frozenUser?.email || '';
  },
});

/**
 * @returns Whether a user has authenticated with SSO
 */
export const rsaIsUserSSO = selector<boolean>({
  key: 'rsaIsUserSSO',
  get: ({ get }) => {
    const user = get(rsaUser);
    return user?.ssoLogin;
  },
});

export const rsaAccountUnit = selector<UnitSystemShorthand>({
  key: 'rsaAccountUnit',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    const featuredIncident = get(rsiFeaturedIncident);

    if (user?.units) {
      return user.units;
    } else if (featuredIncident?.place?.country) {
      const incidentUnits = getUnitsByCountry(featuredIncident.place.country);
      return incidentUnits;
    } else {
      const selectedStationId = get(rsiSelectedStationId);
      const station = get(rssQueryStationById(selectedStationId));
      const stationUnits = getUnitsByCountry(station?.place?.country);
      return stationUnits;
    }
  },
});

export const rsaIsMetric = selector({
  key: 'rsaIsMetric',
  get: ({ get }) => {
    const accountUnit: string = get(rsaAccountUnit);
    return accountUnit === 'si';
  },
});

export const rsaRedirectUrl: RecoilState<string> = atom<string>({
  key: 'rsaRedirectUrl',
  default: '',
  effects: [localStorageEffect<string>(REDIRECT_URL, '')],
});

/**
 * Get's the first org a user is associated with
 */
export const rsaUserOrg = selector<OrgInfo>({
  key: 'rsaUserOrg',
  get: ({ get }) => {
    const user = get(rsaUser);
    // @todo always return first org right now
    return _head(user?.orgs || []) || null;
  },
});

/**
 * Gets all the orgs a user belongs to
 */
export const rsaUserOrgs = selector<OrgInfo[]>({
  key: 'rsaUserOrgs',
  get: ({ get }) => {
    const user = get(rsaUser);
    return user?.orgs || [];
  },
});

/**
 * Returns the preferred coordinate system of a user
 * - If any org a user has is GDA2020, return GDA2020
 * - If no orgs, return WSG83
 */
export const rsaUserCoordinateSystem = selector<CoordinateSystem>({
  key: 'rsaUserCoordinateSystem',
  get: ({ get }) => {
    const userOrgs = get(rsaUserOrgs);
    const isAnyOrgGDA = userOrgs.find((org) => org.gcs === CoordinateSystem.GDA2020);

    if (userOrgs.length === 0) {
      return CoordinateSystem.WGS84;
    } else if (isAnyOrgGDA) {
      return CoordinateSystem.GDA2020;
    }

    return CoordinateSystem.WGS84;
  },
});

/**
 * Returns an empty string if user belongs to multiple separate org types
 * If user only belongs to one org type, return that one.
 */
export const rsaUserOrgType = selector<string>({
  key: 'rsaUserOrgHeaderType',
  get: ({ get }) => {
    const orgs = get(rsaUserOrgs);
    const orgTypes = orgs.map((org) => org.type);
    const dedupedOrgTypes = Array.from(new Set(orgTypes));

    if (dedupedOrgTypes.length !== 1) {
      return '';
    }

    return dedupedOrgTypes[0];
  },
});

/**
 * Gets the user's Id
 */
export const rsaUserId = selector<string>({
  key: 'rsaUserId',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return user?.id || '';
  },
});

/**
 * Whether the user has abearer token: either anonymous or for a logged in user
 */
export const rsaHasBearerToken = selector<boolean>({
  key: 'rsaHasBearerToken',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    return !!user?.bearer;
  },
});

/**
 * Whether has auth-info frozen.
 * - If user logins, but visiting some shared incident from another org, will freeze the current login first.
 */
export const rsaIsFullAuthFrozen = selector<boolean>({
  key: 'rsaIsFullAuthFrozen',
  get: ({ get }) => {
    const frozenUser: PanoUser = get(rsaFrozenUser);
    return !!frozenUser;
  },
});
