import React, { useEffect, useState } from 'react';
import { rsaTrackedUser, rssStationsOwnerType } from 'data';
import { Location } from 'history';
import usePrevious from 'hooks/usePrevious';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getIsPrivateRoute } from 'router/Router.helpers';
import { TrackedUser } from 'types';
import { StationOwnerType } from 'types/enums';
import { logEvent } from 'utils/common';
import { getStationTextByOwnerType } from 'utils/station';

interface PageMeta {
  title: string;
  description: string;
  path: string;
}

const DEFAULT_PAGE_TITLE: string = 'Pano 360';
const DEFAULT_PAGE_DESCRIPTION: string = 'Actionable Intelligence for Wildfire Management';
const DEFAULT_META: { title: string; description: string; path: string } = {
  title: DEFAULT_PAGE_TITLE,
  description: DEFAULT_PAGE_DESCRIPTION,
  path: '/',
};

const PAGE_INFO: (_s: string) => Record<string, { title: string }> = (stationText: string) => ({
  '/map': { title: 'Incidents' },
  '/map/incidents': { title: 'Incidents' },
  '/map/stations': { title: `${stationText}s` },
  '/map/stations/panos': { title: `${stationText}s panorama` },
  '/login': { title: 'Login' },
  '/register': { title: 'Register' },
  '/forgot': { title: 'Forgot password' },
  '/reset': { title: 'Reset password' },
  '/terms': { title: 'Terms of Use' },
  '/privacy': { title: 'Privacy policy' },
  '/invite': { title: 'Invite Pano 360 users' },
  '/community': { title: 'Invite community members' },
  '/incident': { title: 'Incident' },
  '/i': { title: 'Incident' },
  '/station': { title: stationText },
  '/preference/notification': { title: 'Notification preference setting' },
  '/account/settings': { title: 'Account settings' },
  '/expired': { title: 'Expired' },
});

const getPageMeta = (stationText: string, pathname: string): PageMeta => {
  const meta: { title: string } = PAGE_INFO(stationText)[pathname];
  if (meta) {
    return {
      ...DEFAULT_META,
      ...meta,
      path: pathname,
      title: `${meta.title} | ${DEFAULT_PAGE_TITLE}`,
    };
  }

  const parts: string[] = pathname.split('/');
  const id: number = Number(parts[parts.length - 1]);
  parts.pop();
  const basePathName: string = parts.join('/');
  const metaWithParam: { title: string } = PAGE_INFO(stationText)[basePathName];
  if (metaWithParam) {
    return {
      ...DEFAULT_META,
      ...metaWithParam,
      path: pathname,
      title: `${metaWithParam.title} ${id ? '#' + id : ''} | ${DEFAULT_PAGE_TITLE}`,
    };
  }

  return DEFAULT_META;
};

const SEO = (): React.ReactElement => {
  const trackedUser: TrackedUser = useRecoilValue(rsaTrackedUser);
  const stationsOwnerType: StationOwnerType = useRecoilValue(rssStationsOwnerType);
  const location: Location = useLocation();
  const [pageTracked, setPageTracked] = useState(false);
  const previousPathName: string = usePrevious(location.pathname);
  const stationText: string = getStationTextByOwnerType(stationsOwnerType, false);

  const meta: PageMeta = getPageMeta(stationText, location.pathname);

  useEffect(() => {
    // If location changes, always track again.
    if (previousPathName !== location.pathname) {
      setPageTracked(false);
    } else {
      if (pageTracked) {
        return;
      }
    }

    // If it's private, need to wait for user to loaded.
    if (getIsPrivateRoute(location.pathname)) {
      if (trackedUser) {
        logEvent(
          'custom_page_view',
          {
            page_title: meta.title,
          },
          trackedUser,
        );
        setPageTracked(true);
      }
    } else {
      logEvent('custom_page_view', {
        page_title: meta.title,
      });
      setPageTracked(true);
    }
  }, [meta.title, trackedUser, location.pathname, pageTracked, previousPathName]);

  // @jira https://panoai.atlassian.net/browse/RD-975: `key` added to update
  return (
    <Helmet key={location.pathname} defer={false}>
      <meta charSet='utf-8' />
      <title>{meta.title}</title>
      <link rel='icon' href={`${process.env.PUBLIC_URL}/favicon.ico`} />
      <link rel='canonical' href='https://360.pano.ai' />
      <meta name='description' content={meta.description} />
      <meta property='og:title' content={meta.title} />
    </Helmet>
  );
};

export default SEO;
