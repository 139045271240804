import { atom } from 'recoil';
import { FeatureFlagEvaluation, FeatureFlagValue } from 'types';

/**
 * @description Used as a cache of evaluations for previously evaluated feature flags,
 *  to maintain consistent evaluation state for the duration of the user page load.
 */
export const rsfFeatureFlags = atom<{ [key: string]: FeatureFlagEvaluation<FeatureFlagValue> }>({
  key: 'evaluatedFeatureFlags',
  default: {},
});
