import { USER_MAP_LAYERS_VISIBILITY, USER_WEATHER_MAP_LAYER_SELECTION } from 'config/base';
import { localStorageEffect } from 'data/effect';
import { MapApis } from 'data/proxyApi';
import { rsaIsUserOrgPge, rsaOrgWithPowerLines } from 'data/store/authStore';
import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import {
  LayerVisibility,
  LonLat,
  MapAssetDataSet,
  WeatherLayerNames,
  WeatherLayerSelection,
  WeatherMapLayerData,
} from 'types';
import { hasLngLat } from 'utils/incident';
import { UnitFormat, UnitSystem } from 'weatherlayers-gl';

import { rsmMapUnits } from '../mapStore';

export const rsmMapLayersVisibility = atom<LayerVisibility>({
  key: 'rsmMapLayersVisibility',
  default: {
    temperature: false,
    powerline: false,
    privateForests: false,
    rfsBounds: false,
    pgeFacilities: false,
    pgeOverheadMainlines: false,
    pgeOverheadTaplines: false,
    pgeUndergroundMainlines: false,
    pgeUndergroundTaplines: false,
    gtfaDispatchZones: false,
    gtfaPlantationsABP: true,
    gtfaPlantationsAKD: true,
    gtfaPlantationsGPFL: true,
    gtfaPlantationsGTFP: true,
    gtfaPlantationsHVP: true,
    gtfaPlantationsOFO: true,
    gtfaPlantationsPFO: true,
    gtfaPlantationsSFM: true,
    gtfaPlantationsTPPL: true,
    gtfaFocMapGrid: false,
    gtfaCfaMapGrid: false,
    gtfaCfsMapGrid: false,
    hvpPlantation: false,
    sfmPlantations: false,
    rffPlantations: false,
    foricoPlantations: false,
    forico50k: false,
    tfsBrigade: false,
  },
  effects: [localStorageEffect(USER_MAP_LAYERS_VISIBILITY, null)],
});

/** @description Whether or not the map layers menu is open */
export const rsmIsMapLayersMenuOpen = atom<boolean>({
  key: 'rsmIsMapLayersMenuOpen',
  default: false,
});

/** @description Stores which weather map layers are selected */
export const rsmWeatherMapLayerSelection = atom<WeatherLayerSelection>({
  key: 'rsmWeatherMapLayerSelection',
  default: {
    raster: null,
    animation: false,
  },
  effects: [localStorageEffect(USER_WEATHER_MAP_LAYER_SELECTION, null)],
});

/** @description Stores the data from WeatherLayers Cloud */
export const rsmWeatherMapLayerData = atom<WeatherMapLayerData | Record<WeatherLayerNames, never>>({
  key: 'rsmWeatherMapLayerData',
  default: {
    temperature: null,
    humidity: null,
    'wind-speed': null,
    'wind-gust': null,
  },
});

/** @description Get the name of the selected raster layer, if any */
export const rsmSelectedWeatherRasterLayer = selector<WeatherLayerNames>({
  key: 'rsmSelectedWeatherRasterLayer',
  get: ({ get }) => {
    const { raster } = get(rsmWeatherMapLayerSelection);
    return raster;
  },
});

/** @description Get the name of the selected raster layer, if any */
export const rsmSelectedWeatherAnimationLayer = selector<WeatherLayerNames>({
  key: 'rsmSelectedWeatherAnimationLayer',
  get: ({ get }) => {
    const { animation } = get(rsmWeatherMapLayerSelection);
    return animation ? 'wind-speed' : null;
  },
});

/** @description Get an array of unique selected weather layers */
export const rsmSelectedWeatherLayerNames = selector<WeatherLayerNames[]>({
  key: 'rsmSelectedWeatherLayerNames',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);

    const selectedLayers: WeatherLayerNames[] = [];
    if (selectedRasterLayer) {
      selectedLayers.push(selectedRasterLayer);
    }
    if (selectedAnimationLayer && selectedAnimationLayer !== selectedRasterLayer) {
      selectedLayers.push(selectedAnimationLayer);
    }
    return selectedLayers;
  },
});

/** @description Get the name of the visible raster layer if the layer is selected and the data is ready */
export const rsmVisibleRasterLayerName = selector<WeatherLayerNames>({
  key: 'rsmVisibleRasterLayerName',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const weatherMapLayerData = get(rsmWeatherMapLayerData);
    if (!selectedRasterLayer || !weatherMapLayerData[selectedRasterLayer]) {
      return null;
    } else {
      return selectedRasterLayer;
    }
  },
});

/** @description Get the name of the visible animation layer if the data is also ready */
export const rsmVisibleAnimationLayerName = selector<WeatherLayerNames>({
  key: 'rsmVisibleAnimationLayerName',
  get: ({ get }) => {
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);
    const weatherMapLayerData = get(rsmWeatherMapLayerData);
    if (!selectedAnimationLayer || !weatherMapLayerData[selectedAnimationLayer]) {
      return null;
    } else {
      return selectedAnimationLayer;
    }
  },
});

/** @description Boolean to indicate whether there is any weather data visible */
export const rsmIsAnyWeatherLayerVisible = selector<boolean>({
  key: 'rsmIsAnyWeatherLayerVisible',
  get: ({ get }) => {
    const visibleRasterLayer = get(rsmVisibleRasterLayerName);
    const visibleAnimationLayer = get(rsmVisibleAnimationLayerName);
    return !!(visibleRasterLayer || visibleAnimationLayer);
  },
});

/** @description Boolean to indicate whether any weather layer is selected but does not have data yet */
export const rsmIsWeatherLayerLoading = selector<boolean>({
  key: 'rsmIsWeatherLayerLoading',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);
    const weatherMapData = get(rsmWeatherMapLayerData);

    if (selectedRasterLayer && !weatherMapData[selectedRasterLayer]) {
      return true;
    } else if (selectedAnimationLayer && !weatherMapData[selectedAnimationLayer]) {
      return true;
    }
    return false;
  },
});

/** @description Gets the unit system to use for WeatherLayers */
export const rsmWeatherLayerUnitSystem = selector<UnitSystem | null>({
  key: 'rsmWeatherLayerUnitSystem',
  get: ({ get }) => {
    const unitSystem = get(rsmMapUnits);
    return unitSystem === UnitSystem.METRIC ? UnitSystem.METRIC_KILOMETERS : unitSystem;
  },
});

/** @description Gets the unit format to use for WeatherLayers */
export const rsmWeatherRasterLayerUnitFormat = selector<UnitFormat | null>({
  key: 'rsmWeatherLayerUnitFormat',
  get: ({ get }) => {
    const layerName = get(rsmWeatherMapLayerSelection).raster;
    const weatherMapLayerData = get(rsmWeatherMapLayerData);
    return weatherMapLayerData[layerName]?.datasetMetadata?.unitFormat || null;
  },
});

/** @description Gets latest delivery time for a layer */
export const rsmGetLatestDeliveryTimeOfLayer = selectorFamily<string, WeatherLayerNames>({
  key: 'rsmGetLatestDeliveryTimeOfLayer',
  get:
    (layerName) =>
    ({ get }) => {
      const weatherMapLayerData = get(rsmWeatherMapLayerData);
      return weatherMapLayerData[layerName]?.imageData?.referenceDatetime;
    },
});

/** @description Get the latest update time of visible weather layers */
export const rsmLastWeatherLayerUpdateTime = selector<Date>({
  key: 'rsmLastWeatherLayerUpdateTime',
  get: ({ get }) => {
    const visibleRasterLayer = get(rsmVisibleRasterLayerName);
    const visibleAnimationLayer = get(rsmVisibleAnimationLayerName);
    const rasterUpdateTime = get(rsmGetLatestDeliveryTimeOfLayer(visibleRasterLayer));
    const animationUpdateTime = get(rsmGetLatestDeliveryTimeOfLayer(visibleAnimationLayer));

    const updateTimes = [];
    if (rasterUpdateTime) {
      updateTimes.push(new Date(rasterUpdateTime).getTime());
    }
    if (animationUpdateTime) {
      updateTimes.push(new Date(animationUpdateTime).getTime());
    }
    if (updateTimes.length === 0) {
      return null;
    }
    return new Date(Math.min(...updateTimes));
  },
});

/** @description Get the last updated time for visible weather layer data to display according to locale */
export const rsmLastUpdateTimeLocalized = selector<string>({
  key: 'rsmLastUpdateTimeLocalized',
  get: ({ get }) => {
    const lastUpdatedTime = get(rsmLastWeatherLayerUpdateTime);
    const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
    const options: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    };
    return lastUpdatedTime?.toLocaleString(locale, options);
  },
});

/**
 * @description - Get/set wether the give map layer is visible
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 */
export const rsmMapLayerVisibilityByName = selectorFamily<boolean | DefaultValue, string>({
  key: 'rsmMapLayerVisibilityByName',
  get:
    (layerName) =>
    ({ get }) => {
      const mapLayers = get(rsmMapLayersVisibility);
      return !!mapLayers[layerName];
    },
  set:
    (layerName) =>
    ({ set }, isVisible) => {
      set(rsmMapLayersVisibility, (prevValues) => ({
        ...prevValues,
        [layerName]: isVisible instanceof DefaultValue ? false : isVisible,
      }));
    },
});

/**
 * @description Returns the map layers eligble for Nearby assets queries
 * - These are the map layers served from our GeoJSON server
 * - Some Map Layers come from mapbox.
 * - **NOTE** - These are restricted to those which the user is eligible to query
 *
 */
export const rsmMapLayersForUser = selector({
  key: 'rsmMapLayersForUser',
  get: ({ get }) => {
    const orgWithPowerLines: boolean = get(rsaOrgWithPowerLines);
    const isUserOrgPge: boolean = get(rsaIsUserOrgPge);

    return [
      ...(orgWithPowerLines ? [MapAssetDataSet.PowerLine] : []),
      ...(isUserOrgPge
        ? [MapAssetDataSet.PgeLocation, MapAssetDataSet.PgeSubstation, MapAssetDataSet.PgeHydroPlant]
        : []),
    ];
  },
});

/**
 * @description - Get customer assets near a specific lon/lat
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 */
export const rsmFetchNearByAssets = selectorFamily({
  key: 'rsmFetchNearByAssets',
  get:
    (lonLat: LonLat) =>
    async ({ get }) => {
      if (!hasLngLat(lonLat)) {
        return null;
      }

      const mapLayers = get(rsmMapLayersForUser);

      if (!mapLayers.length) {
        return null;
      }

      return await MapApis.apiGetNearByAssets(lonLat.lat, lonLat.lon, 2, mapLayers);
    },
});

export const rsmHighlighPgeFacilityIds = atom<string[]>({
  key: 'rsmHighlighPgeFacilityIds',
  default: [],
});
