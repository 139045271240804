import React from 'react';
import iconCourthouse from 'assets/icons/courthouse.svg';
import iconForestShield from 'assets/icons/forest-shield.svg';
import iconGovTruck from 'assets/icons/gov-truck.svg';
import iconInsuranceOrg from 'assets/icons/insurance-org.svg';
import iconLandownerOrg from 'assets/icons/landowner-org.svg';
import iconPicMonitor from 'assets/icons/pic-monitor.svg';
import iconTransmissionUtilityOrg from 'assets/icons/transmission-utility-org.svg';
import iconWaterSpigot from 'assets/icons/water-spigot.svg';
import { ORG_TYPES } from 'types';

import Box from '@mui/material/Box';

import styles from './OrgIcon.styles';

interface IProps {
  orgType: string;
}

const OrgIcon = ({ orgType }: IProps) => {
  if (orgType === ORG_TYPES.PANO) {
    return <Box component='img' alt='PIC Icon' src={iconPicMonitor} data-cy='icon-pano-org' />;
  } else if (orgType === ORG_TYPES.UTILITY) {
    return (
      <>
        <Box
          component='img'
          alt='Transmission Line Icon'
          sx={styles.iconTransmissionLine}
          src={iconTransmissionUtilityOrg}
          data-cy='icon-transmission-utility-org'
        />
        <Box
          component='img'
          alt='Water Spigot Icon'
          sx={styles.iconWaterSpigot}
          src={iconWaterSpigot}
          data-cy='icon-water-spigot'
        />
      </>
    );
  } else if (orgType === ORG_TYPES.OWNER) {
    return <Box component='img' alt='Landowner Icon' src={iconLandownerOrg} data-cy='icon-landowner-org' />;
  } else if (orgType === ORG_TYPES.INSURANCE) {
    return <Box component='img' alt='Insurance Icon' src={iconInsuranceOrg} data-cy='icon-insurance-org' />;
  } else if (orgType === ORG_TYPES.GOVERNMENT) {
    return (
      <Box sx={styles.govIcons}>
        <Box sx={styles.govTopIcons}>
          <Box component='img' alt='Court House Icon' src={iconCourthouse} data-cy='icon-court-house' />
          <Box component='img' alt='Shield Icon' src={iconForestShield} data-cy='icon-forest-shield' />
        </Box>
        <Box component='img' alt='Truck Icon' src={iconGovTruck} data-cy='icon-gov-truck' />
      </Box>
    );
  } else {
    return null;
  }
};

export default OrgIcon;
