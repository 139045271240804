import React, { useEffect, useState } from 'react';
import SuspenseRipple from 'componentLibrary/SuspenseRipple';
import { rsaIdPDiscoveryEmail, rsaRedirectUrl, rsaUser } from 'data';
import { AuthApis } from 'data/proxyApi';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const SSOCallback = () => {
  const history = useHistory();
  const queryParams = useQuery();
  const token = queryParams.get('token');
  const setUser = useSetRecoilState(rsaUser);
  const setIdPDiscoveryEmail = useSetRecoilState(rsaIdPDiscoveryEmail);

  const [redirectUrl, setRedirectUrl] = useRecoilState(rsaRedirectUrl);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    /**
     * If a user has successfully auth'd with SSO and the SP has granted a token
     * - grant the user access to application and clear any info used for Login errors
     * - else redirect them to the login page
     */
    const redirectUserOnAuth = async () => {
      const response = await AuthApis.apiGetPanoAccountForSSO({ token });
      const { data: user, status } = response;
      if (status === 200) {
        setUser(user);
        setRedirectUrl('');
        setIdPDiscoveryEmail(null);
        history.push(redirectUrl || '/map');
      } else {
        history.push('/login');
      }
    };

    if (!isRedirecting) {
      setIsRedirecting(true);
      redirectUserOnAuth();
    }
  }, [history, isRedirecting, redirectUrl, setIdPDiscoveryEmail, setRedirectUrl, setUser, token]);

  return <SuspenseRipple isLoading={true} />;
};

export default SSOCallback;
