import React, { useEffect, useRef } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { rscListScrollTop } from 'data';
import { Location } from 'history';
import _debounce from 'lodash/debounce';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isHomePage } from 'utils/window';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  id?: string;
  children: React.ReactNode;
  withHeader?: boolean;
  withFooter?: boolean;
  className?: string;
  style?: Record<string, unknown>;
  sx?: SxProps<Theme>;
}

const Layout = ({
  id = '',
  children,
  withHeader = false,
  withFooter = true,
  className = '',
  style = {},
  sx = {},
}: Props): React.ReactElement => {
  const [listScrollTop, setListScrollTop] = useRecoilState(rscListScrollTop);
  const listRef: React.RefObject<HTMLElement> = useRef(null);
  const location: Location = useLocation();
  const params: {
    pageNo: string;
    page: string;
  } = useParams();

  const onScroll: (e: React.UIEvent<HTMLElement>) => void = _debounce((e) => {
    // Store the scrolling position for incident list.
    if (isHomePage(location.pathname)) {
      setListScrollTop(e.target?.scrollTop);
    }
  }, 50);

  useEffect(() => {
    // Scroll to top when it's not homepage
    if (!isHomePage(location.pathname)) {
      // timeout to wait for list to be ready
      setTimeout(() => {
        listRef?.current && (listRef.current.scrollTop = 0);
      }, 1);
    }
  }, [listRef, params?.pageNo, params?.page, location.pathname]);

  useEffect(() => {
    if (listScrollTop !== listRef.current.scrollTop) {
      // timeout to wait for list to be ready
      setTimeout(() => {
        listRef.current && (listRef.current.scrollTop = listScrollTop);
      });
    }
  }, [listScrollTop]);

  return (
    <>
      {withHeader && <Header />}
      <Box
        component={'main'}
        id={id}
        className={className}
        style={style}
        ref={listRef}
        onScroll={onScroll}
        sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
          {
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        ]}
      >
        {children}
      </Box>
      {withFooter && <Footer />}
    </>
  );
};

export default Layout;
