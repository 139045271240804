export const scaleNumberTo = (num: number, decimal = 3): number => parseFloat(num.toFixed(decimal));

export const m2ft = (meter: number): number => Math.ceil(meter * 3.28084);

export const km2mi = (km: number): string => (km * 0.621371).toFixed(2);

export const metersToKilometers = (meters: number): number => Math.round((meters / 1000) * 100) / 100;
export const metersToMiles = (meters: number): number => Math.round((meters * 100) / 1609) / 100;

export const getDistance = (units = 'si', distance: number, large = true): string => {
  if (units === 'iu') {
    return large ? km2mi(distance) + 'mi' : m2ft(distance) + 'ft';
  }

  return large ? distance.toFixed(2) + 'km' : Math.ceil(distance) + 'm';
};
