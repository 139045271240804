import React from 'react';
import { APP_ENV, IS_PROD, MIXPANEL_TOKEN, SENTRY_DSN, TRACKING_ENABLED } from 'config/base';
import mixpanel from 'mixpanel-browser';
import { createRoot, Root } from 'react-dom/client';

import * as Sentry from '@sentry/react';

import packageJson from '../package.json';

import App from './App';

if (IS_PROD) {
  console.log = function (): void {
    // do nothing.
  };
  console.time = function (): void {
    // do nothing.
  };
  console.timeEnd = function (): void {
    // do nothing.
  };
}

(async (): Promise<void> => {
  APP_ENV !== 'local' &&
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: APP_ENV,
      release: `pano-360-client@${packageJson.version}`,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

  TRACKING_ENABLED && mixpanel.init(MIXPANEL_TOKEN);

  const container: HTMLElement = document.getElementById('root');
  const root: Root = createRoot(container);
  root.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
