import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { PanoImagesData, RssGetLatestPanoPerMinParams, RssGetStationPanoDataParams, Station } from 'types';
// import { nowMs } from 'utils/moment';
import { getPanoDataPath, getStationDois, getStationFovPan } from 'utils/station';

import PanoImagesApiTier from './PanoImagesApiTier';
import PanoImagesCacheTier from './PanoImagesCacheTier';

export default class StationApi {
  api: AxiosInstance;
  // regular RangeFilter are with keys in minutes
  private rangeFiltersMap: Record<number, PanoImagesCacheTier> = {};
  // OZ RangeFilter are with keys in seconds
  private ozRangeFiltersMap: Record<number, PanoImagesCacheTier> = {};

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    this.api = await authApi();
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  getRangeFilter(stationId: number): PanoImagesCacheTier {
    const rangeFilter: PanoImagesCacheTier = this.rangeFiltersMap[stationId];
    if (rangeFilter) {
      return rangeFilter;
    }
    const apiTier: PanoImagesApiTier = new PanoImagesApiTier(null, stationId);
    const cacheTier: PanoImagesCacheTier = new PanoImagesCacheTier(apiTier);
    this.rangeFiltersMap[stationId] = cacheTier;
    return cacheTier;
  }

  getOzRangeFilter(stationId: number): PanoImagesCacheTier {
    const rangeFilter: PanoImagesCacheTier = this.ozRangeFiltersMap[stationId];
    if (rangeFilter) {
      return rangeFilter;
    }
    const apiTier: PanoImagesApiTier = new PanoImagesApiTier(null, stationId, true);
    const cacheTier: PanoImagesCacheTier = new PanoImagesCacheTier(apiTier);
    this.ozRangeFiltersMap[stationId] = cacheTier;
    return cacheTier;
  }

  /**
   * @param ifRange If exists, sets an If-Range header on the request. This will cause the API to return only
   * @param shouldSkipErrorHandling - Whether we should skip generic error hanlding (true for polling requests)
   * stations which have been updated since the previous time.
   *
   * @NOTE - THis is currently only called with the previous time we fetched the station list, stored in rssUserStationsTs
   * @returns an array of active and inactive stations.
   * - NOTE: ECs with null or 'registered' status will be filtered out
   */

  async apiGetStations(ifRange = 0, shouldSkipErrorHandling = false): Promise<Station[]> {
    const headers: Record<string, string> = ifRange ? { 'If-Range': new Date(ifRange).toUTCString() } : {};
    try {
      const { data }: { data: Station[] } = await this.api.get('/ecs', { headers, shouldSkipErrorHandling });
      data.forEach((station) => {
        station.dois = getStationDois(station);
        station.fovPan = getStationFovPan(station);
      });

      return data;
    } catch (e) {
      console.error('[Pano] API Error /ecs', e);
      return [];
    }
  }

  async apiGetStationPanoData(params: RssGetStationPanoDataParams): Promise<PanoImagesData[]> {
    const { stationId, from, to, isOpticalZoom } = params;

    const path: string = getPanoDataPath(stationId, from, to, isOpticalZoom);
    try {
      const { data: panoMapData } = await this.api.get(path);
      return panoMapData;
    } catch (err) {
      console.log('[Pano] API Error', err);
      return [] as PanoImagesData[];
    }
  }

  async apiGetLatestPanoPerMin(params: RssGetLatestPanoPerMinParams): Promise<PanoImagesData[]> {
    const { stationId, currentPanoData, isOpticalZoom = false } = params;
    const currentLastFrame: PanoImagesData = currentPanoData[currentPanoData.length - 1];
    if (!currentLastFrame || currentLastFrame.length < 2) {
      return [] as PanoImagesData[];
    }

    try {
      const path: string = getPanoDataPath(
        stationId,
        isOpticalZoom ? currentLastFrame[1] : Math.floor(currentLastFrame[1] / 60) + 1,
        0,
        isOpticalZoom,
      );
      const { data: panoMapData } = await this.api.get(path);
      return panoMapData;
    } catch (e) {
      console.log('[Pano] API Error', e);
      return [];
    }
  }
}
