import React, { useEffect } from 'react';
import { rsaFrozenUser, rsaIsFullAuth, rsaIsPendingMfaSetup, rsaRedirectUrl, rsaSecuritySettingsChanged } from 'data';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getIsSharedPage } from '../Router.helpers';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuthenticated = useRecoilValue(rsaIsFullAuth);
  const isPendingMfaSetup = useRecoilValue(rsaIsPendingMfaSetup);
  const securitySettingsChanged = useRecoilValue(rsaSecuritySettingsChanged);
  const setRedirectUrl = useSetRecoilState(rsaRedirectUrl);

  const frozenUser = useRecoilValue(rsaFrozenUser);

  const location = useLocation();
  const isOnSharedPage = getIsSharedPage(location.pathname);

  useEffect(() => {
    if (!isAuthenticated && !frozenUser && !isPendingMfaSetup && !securitySettingsChanged) {
      setRedirectUrl(location.pathname);
    }
  });

  if (isOnSharedPage) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else if (isPendingMfaSetup && location.pathname !== '/account/settings') {
    return <Route {...rest} render={() => <Redirect to='/login' />} />;
  } else if ((!isAuthenticated && !frozenUser && !isPendingMfaSetup) || securitySettingsChanged) {
    return <Route {...rest} render={() => <Redirect to='/login' />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
