import { rspEndingOzSession, rspIsStationInOz, rspRemoveOzStation, rssIsStationOzCapable } from 'data';
import { OzApis } from 'data/proxyApi';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { OzEndedApiResponseData, OzEndedReason } from 'types';

interface Returned {
  endStationOz: (reason: OzEndedReason) => Promise<OzEndedApiResponseData>;
}

/**
 * @deprecated - This should be done in a recoil selector
 * @param stationId
 * @returns
 */
export function useEndStationOz(stationId: number): Returned {
  const hasOzPermission: boolean = useRecoilValue(rssIsStationOzCapable(stationId));
  const isStationInOz: boolean = useRecoilValue(rspIsStationInOz(stationId));
  const setEndingOzSession: SetterOrUpdater<boolean> = useSetRecoilState(rspEndingOzSession);
  const removeOzStation: SetterOrUpdater<number> = useSetRecoilState(rspRemoveOzStation);

  return {
    // We always end the OZ session on Front-end
    // Also try to call API to end OZ session. The result is ignored.
    endStationOz: async (reason: OzEndedReason): Promise<OzEndedApiResponseData> => {
      if (!hasOzPermission || !isStationInOz) {
        return null;
      }

      setEndingOzSession(true);
      removeOzStation(stationId);
      try {
        return await OzApis.apiDeleteEndStationOz(stationId, reason);
      } catch (e) {
        // The account may be just the OZ viewer so this call fails.
        // In that case, we end OZ session on front-end still, but do not refresh station status
        return null;
      } finally {
        setEndingOzSession(false);
      }
    },
  };
}
