import MESSAGES from 'config/messages';
import { rspIsStationInOz, rspUpdateOzStation } from 'data/store/ozStore';
import { rssIsStationOzCapable } from 'data/store/stationStore';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { atom, DefaultValue, RecoilState, selector } from 'recoil';
import {
  FulfilledPendingPtzApiCalls,
  NotificationMessage,
  OpticalZoomData,
  PendingPtzApiCall,
  PendingPtzApiCallOnStations,
  RemovePendingPtzParams,
  UpdateStationPtzParams,
} from 'types';
import { AlertLevel } from 'types/enums';
import { removeItemFromArray } from 'utils/array';
import { deleteObjectField } from 'utils/object';

/**
 * When user clicks the PTZ command very quickly, our Recoil state may keep stale `PendingPtzApiCall`.
 * For example:
 * 1. `PendingPtzApiCall` has [1, 2, 3, 4]
 * 2. User removes 1, equivalently, sets `PendingPtzApiCall` [2, 3, 4]
 * 3. User removes 2, equivalently, sets `PendingPtzApiCall` [1, 3, 4]
 * Then the item `1` is stale.
 * So, `fulfilledPendingPtzApiCalls` stores those fulfilled correlation-ids, when setting
 * `PendingPtzApiCall`, filter them out.
 * @jira https://panoai.atlassian.net/browse/RD-1031
 */
let fulfilledPendingPtzApiCalls: FulfilledPendingPtzApiCalls = {};

export const clearFulfilledPendingPtzApiCalls = (): void => {
  fulfilledPendingPtzApiCalls = {};
};

/**
 * A map of pending Pan Tilt Zoom commands on stations
 * - station id => an array of pending PTZ commands
 */
export const rspPendingPtzApiCallOnStations: RecoilState<PendingPtzApiCallOnStations> =
  atom<PendingPtzApiCallOnStations>({
    key: 'rspPendingPtzApiCallOnStations',
    default: {},
  });

export const _rspOzControlNotification: RecoilState<NotificationMessage> = atom<NotificationMessage>({
  key: '_rspOzControlNotification',
  default: null,
});

/**
 * Add a pending Pan Tilt Zoom command
 */
export const rspAddPendingPtzApiCallOnStations: RecoilState<PendingPtzApiCall> = selector<PendingPtzApiCall>({
  key: 'rspPendingPtzApiCallOnStations/add',
  get: () => null, // @note never use `get`
  set: ({ get, set }, newCall) => {
    if (newCall instanceof DefaultValue) {
      return;
    }

    const pendingCalls: PendingPtzApiCallOnStations = get(rspPendingPtzApiCallOnStations);
    const { stationId }: PendingPtzApiCall = newCall;
    set(rspPendingPtzApiCallOnStations, {
      ...pendingCalls,
      [stationId]: _concat(pendingCalls[stationId] || [], newCall),
    });
  },
});

/**
 * Remove all pending Pan Tilt Zoom commands for an EC
 */
export const rspClearPendingPtzApiCallsOnStations: RecoilState<number> = selector<number>({
  key: 'rspPendingPtzApiCallOnStations/clear',
  get: () => null, // @note never use `get`
  set: ({ get, set }, stationId) => {
    if (stationId instanceof DefaultValue) {
      return;
    }

    set(rspPendingPtzApiCallOnStations, deleteObjectField(get(rspPendingPtzApiCallOnStations), stationId));
  },
});

/**
 * Remove a pending Pan Tilt Zoom command given a stationId and a correlationId
 */
export const rspRemovePendingPtzApiCallOnStations: RecoilState<RemovePendingPtzParams> =
  selector<RemovePendingPtzParams>({
    key: 'rspPendingPtzApiCallOnStations/remove',
    get: () => null, // @note never use `get`
    set: ({ get, set }, toBeRemoved) => {
      if (toBeRemoved instanceof DefaultValue) {
        return;
      }

      const pendingCalls: PendingPtzApiCallOnStations = get(rspPendingPtzApiCallOnStations);
      const ozControlNotification: NotificationMessage = get(rspOzControlNotification);

      const { stationId, correlationId } = toBeRemoved;
      const pendingCallsOnEc: PendingPtzApiCall[] = pendingCalls[stationId];
      if (!pendingCallsOnEc || pendingCallsOnEc.length <= 0) {
        return;
      }

      const pendingPtzIndex: number = _findIndex(pendingCallsOnEc, { correlationId });
      if (pendingPtzIndex >= 0) {
        fulfilledPendingPtzApiCalls[correlationId] = true;
        // If previously shows 409, now the latest PTZ command has been fulfilled, hide the notification
        // @jira https://panoai.atlassian.net/browse/RD-1051
        if (ozControlNotification?.level === AlertLevel.Warning && pendingPtzIndex === pendingCallsOnEc.length - 1) {
          set(rspOzControlNotification, null);
        }

        const remainingPtzCallsOnEc: PendingPtzApiCall[] = removeItemFromArray(pendingCallsOnEc, pendingPtzIndex);
        const notFulfilled: PendingPtzApiCall[] = remainingPtzCallsOnEc.filter(
          (pending) => !fulfilledPendingPtzApiCalls[pending.correlationId],
        );
        if (notFulfilled.length > 0) {
          set(rspPendingPtzApiCallOnStations, {
            ...pendingCalls,
            [stationId]: notFulfilled,
          });
        } else {
          // All pending PTZ commands are fulfilled, hide the notification.
          set(rspOzControlNotification, null);
          set(rspPendingPtzApiCallOnStations, deleteObjectField(pendingCalls, stationId));
        }
      }
    },
  });

// update station PTZ once upon OZ image data arrives
/**
 *
 */
export const rspUpdateStationPtz: RecoilState<UpdateStationPtzParams> = selector<UpdateStationPtzParams>({
  key: 'rspUpdateStationPtz',
  get: () => null, // @note never call `get`
  set: ({ set, get }, params) => {
    if (params instanceof DefaultValue) {
      return;
    }

    const { stationId, ozImageData } = params;
    // If the no permission to OZ, ignore.
    if (!get(rssIsStationOzCapable(stationId)) || !get(rspIsStationInOz(stationId))) {
      return;
    }

    const lastImageMeta: OpticalZoomData = ozImageData[ozImageData.length - 1];
    if (lastImageMeta.length < 7) {
      console.debug('[debug] Pano image data has wrong data format');
      return;
    }

    const [, , imgPan, imgTilt, imgZoom] = lastImageMeta;
    set(rspUpdateOzStation, {
      stationId,
      loading: false,
      pan: imgPan,
      tilt: imgTilt,
      zoom: imgZoom,
    });

    const pendingPtzApiCalls: PendingPtzApiCall[] = get(rspPendingPtzApiCallOnStations)[stationId];
    // If user has changed PTZ, when PTZ changes to the target values, hide the notification.
    if (pendingPtzApiCalls && pendingPtzApiCalls.length > 0) {
      // If any zoom image item matches correlation id with previous PTZ calls,
      // we know that the previous PTZ command has been fulfilled, hide notification.
      for (const element of ozImageData) {
        const meta: OpticalZoomData = element;
        if (meta.length >= 7) {
          const imgCorrelationId: string = meta[6];
          const pendingPtz: PendingPtzApiCall = _find(pendingPtzApiCalls, { correlationId: imgCorrelationId });
          if (pendingPtz && imgCorrelationId === pendingPtz.correlationId) {
            set(rspRemovePendingPtzApiCallOnStations, {
              stationId,
              correlationId: imgCorrelationId,
            });
          }
        }
      }
    }
  },
});

export const rspOzControlNotification: RecoilState<NotificationMessage> = selector<NotificationMessage>({
  key: 'rspOzControlNotification',
  get: ({ get }) => {
    return get(_rspOzControlNotification);
  },
  set: ({ set }, newValue) => {
    if (newValue === undefined) {
      set(_rspOzControlNotification, {
        level: AlertLevel.Error,
        message: MESSAGES.OZ_UPDATE_ERR,
      });
    } else {
      set(_rspOzControlNotification, newValue);
    }
  },
});
