import { FormikValues } from 'formik';
import _compact from 'lodash/compact';
import _identity from 'lodash/identity';
import _isString from 'lodash/isString';
import _mapValues from 'lodash/mapValues';
import _pickBy from 'lodash/pickBy';
import _trim from 'lodash/trim';
import _uniq from 'lodash/uniq';
import { Incident, Station } from 'types';

// eslint-disable-next-line no-unused-vars
export const trimStringValues = <T extends object>(obj: T): { [P in keyof T]: string | T[keyof T] } =>
  _mapValues(obj, (v) => (_isString(v) ? _trim(v) : v));

export const compactObject = (obj: Incident | FormikValues): Incident | FormikValues =>
  _pickBy(
    _mapValues(obj, (v) => (_isString(v) ? _trim(v) : v)),
    _identity,
  );

export const stringifyLocation = (station: Incident | Station | FormikValues): string =>
  _compact(_uniq([station?.place?.city, station?.place?.county])).join(', ');

export const deleteObjectField = <T>(
  obj: Record<string | number, T>,
  field: string | number,
): Record<string | number, T> => {
  const cloned: Record<string | number, T> = { ...obj };
  delete cloned[field];
  return cloned;
};
