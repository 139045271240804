import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  footer: (theme: Theme) => ({
    fontSize: theme.typography.body2,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    lineHeight: 2,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      display: 'block',
      textAlign: 'center',
    },
  }),
};

export default styles;
