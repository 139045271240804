export function getUnitsByCountry(country: string) {
  const countryStandardized = country?.toUpperCase();
  switch (countryStandardized) {
    case 'US':
      return 'iu';
    case 'AU':
    case 'CA':
      return 'si';
    default:
      return 'si';
  }
}
