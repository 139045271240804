import { API_TRACE_HEADER } from 'config/constants';
import {
  rscGlobalAlert,
  rscMyCorrelationIds,
  rscSetMyCorrelationId,
  rspAddOzStation,
  rspFullScreenInOz,
  rspFullScreenStationId,
  rspIsStationInOz,
  rspStationsCanvasPos,
  rspUpdateOzStationsStatus,
  rssIsStationOzCapable,
  rssQueryStationById,
  rssUpdateStationOz,
} from 'data';
import { OzApis } from 'data/proxyApi';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ByUser,
  CanvasPos,
  GlobalAlertParams,
  HttpStatusCode,
  IOzStation,
  MyCorrelationIdMap,
  OzStatus,
  OzUpdatedStationParams,
  Station,
  StationCamera,
  STATUS_MODE,
  UpdateOzStationStatusParams,
} from 'types';
import { mergeObjectInArrays } from 'utils/array';
import { assertValueDefined } from 'utils/common';

interface Returned {
  startStationOz: (_incBearing: number, _pan: number) => Promise<boolean>;
}

export function useStartStationOz(stationId: number): Returned {
  const hasOzPermission: boolean = useRecoilValue(rssIsStationOzCapable(stationId));
  const stationsCanvasPos: Record<number, CanvasPos> = useRecoilValue(rspStationsCanvasPos);
  const addOzStation: SetterOrUpdater<IOzStation> = useSetRecoilState(rspAddOzStation);
  const setGlobalAlert: SetterOrUpdater<GlobalAlertParams> = useSetRecoilState(rscGlobalAlert);
  const setMyCorrelationId: SetterOrUpdater<string> = useSetRecoilState(rscSetMyCorrelationId);
  const updateStationOz: SetterOrUpdater<OzUpdatedStationParams> = useSetRecoilState(rssUpdateStationOz);
  const myCorrelationIds: MyCorrelationIdMap = useRecoilValue(rscMyCorrelationIds);
  const station: Station = useRecoilValue(rssQueryStationById(stationId));
  const [fullScreenInOz, setFullScreenInOz] = useRecoilState(rspFullScreenInOz);
  const fullScreenStationId: number = useRecoilValue(rspFullScreenStationId);
  const updateOzStationsStatus: SetterOrUpdater<UpdateOzStationStatusParams> =
    useSetRecoilState(rspUpdateOzStationsStatus);
  const opticalZoom: boolean = useRecoilValue(rspIsStationInOz(stationId));

  return {
    startStationOz: async (incBearing = 0, pan = 0): Promise<boolean> => {
      // If the no permission to OZ, ignore.
      if (!hasOzPermission) {
        return false;
      }

      // If the station is already in OZ, but user is viewing normal player
      // He clicks the `Optical Zoom` button, just show it in full screen without modal.
      if (fullScreenStationId === stationId && !fullScreenInOz && opticalZoom) {
        updateOzStationsStatus({
          stationId,
          status: OzStatus.Void,
        });
        setFullScreenInOz(true);
        return true;
      }

      const pos: CanvasPos = stationsCanvasPos[stationId];
      const bearing: number = pos?.bearing;
      pan = pan || Math.ceil(bearing === undefined || bearing === null ? incBearing || 0 : bearing);
      pan = pan % 360;

      try {
        const { status, data, headers } = await OzApis.apiPutStartStationOz(stationId, pan);
        if (status === HttpStatusCode.Ok || status === HttpStatusCode.PreconditionFailed) {
          const manual: StationCamera = (data?.cameras || []).find((i) => i.state === STATUS_MODE.MANUAL);
          const period: number = manual?.period;
          assertValueDefined(`EC-${stationId} after starting OZ`, manual);
          assertValueDefined(`put EC-${stationId} period`, period);
          const byMe: boolean =
            manual.correlationId === headers[API_TRACE_HEADER] || !!myCorrelationIds[manual.correlationId];

          byMe && setMyCorrelationId(headers[API_TRACE_HEADER]);
          addOzStation({
            stationId,
            loading: true,
            tiltMax: station.cameraType.tiltMax,
            tiltMin: station.cameraType.tiltMin,
            zoomMax: station.cameraType.zoomMax,
            period,
            // Reaching here, the user starts a new OZ session.
            // lastOzSequence[0] is from the previous old OZ session `startTime`, lastOzSequence[1] is time of the last OZ image.
            lastOzSequence: data.lastOzSequence, // in seconds.
            startTime: 0, // in seconds. `startTime` is unknown at this moment, since EC does not return any OZ images for this session yet.
            byUser: byMe ? ByUser.Me : ByUser.Others,
            correlationId: manual.correlationId,
            pan: manual.pan,
            tilt: manual.tilt,
            zoom: manual.zoom,
          });
          // Refresh stations to update the OZ status
          updateStationOz({
            id: stationId,
            lastOzSequence: data.lastOzSequence,
            modifyTime: data.modifyTime,
            cameras: mergeObjectInArrays(station.cameras, data.cameras),
          });
          return true;
        }

        return false;
      } catch (e) {
        setGlobalAlert(null);
        return false;
      }
    },
  };
}
