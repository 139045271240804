import { exactType, UnitSystemShorthand } from './common';
import { CoordinateSystem, Permission, Role } from './enums';
import { Station } from './station';

export interface OrgInfo {
  id: string;
  name: string;
  lon: number;
  lat: number;
  zoom: number;
  role?: Role;
  country?: string;
  type?: string;
  orgPermissions?: Permission[];
  /** Multiple Factor Authorization: non-nullable boolean */
  mfa: boolean;
  gcs: CoordinateSystem;
}

export interface PanoUser {
  id: string;
  units?: UnitSystemShorthand;
  bearer?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  lastLogin?: number;
  notifyPhone?: boolean;
  notifyEmail?: boolean;
  orgs?: OrgInfo[];
  version: number;
  phoneNotify?: number;
  phoneMfa?: number;
  company?: string;
  tosTime?: number;
  tosMethod?: string;
  title?: string;
  super?: boolean;
  /** Wehther the user auth'd with SSO */
  ssoLogin?: boolean;

  /** temp storage of user password for sending MFA code */
  encodedPassword?: string;
}

export interface TrackedUser {
  user_id: string;
  user_org_name: string;
  user_org_type: string;
}

export const exactTrackedUser: <U extends TrackedUser & { [P in Exclude<keyof U, keyof TrackedUser>]: never }>(
  u: U,
) => TrackedUser = exactType<TrackedUser>();

export interface RegisterFormValues {
  firstName?: string;
  lastName?: string;
  company?: string;
  title?: string;
  acceptTos?: boolean;
  password?: string;
}

export interface LoginFormValues {
  email: string;
  password: string;
  acceptTos?: boolean; // after registration, no need to accept tos explicitly.
  smsCode?: string;
}

export type AccountSettingsPayload = Pick<PanoUser, 'notifyEmail' | 'notifyPhone' | 'phoneNotify' | 'phoneMfa'>;

export type RsaHasPermissionParams = {
  stations: Station[];
  permission: Permission;
};

export type RasLogoutParams = {
  redirectUrl?: string;
  callLogoutApi?: boolean;
};
